import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity, Image, TextInput, ActivityIndicator, StyleSheet } from 'react-native';
import { getCollections, createCollection } from '../../services/collectionService';
import bucketListService from '../../services/bucketListService';

const CollectionsManager = ({ favitem = null , onClose, onCollectionAdded = () => {} }) => {
    const [collections, setCollections] = useState([]);  // State for collections
    const [selectedCollections, setSelectedCollections] = useState([]);  // State for selected collections
    const [collection_name, setCollectionName] = useState('');  // State for new collection name
    const [loading, setLoading] = useState(false);  // Loader state
    const [view, setView] = useState('list');  // 'list', 'form', 'zero'

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = async () => {
        try {
            setLoading(true);
            const response = await getCollections();
            setCollections(response.data);
            setView(response.data.length === 0 ? 'zero' : 'list');
        } catch (error) {
            console.error('Failed to fetch collections:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxToggle = (collectionId) => {
        if (selectedCollections.includes(collectionId)) {
            setSelectedCollections(selectedCollections.filter(id => id !== collectionId));
        } else {
            setSelectedCollections([...selectedCollections, collectionId]);
        }
    };

    const handleSaveToCollections = async () => {
        try {
            setLoading(true);
            for (const collectionId of selectedCollections) {
                await bucketListService.addExperienceToBucketList(favitem?.id, collectionId);
            }
            setLoading(false);
            onClose();  // Close the form after saving
        } catch (error) {
            setLoading(false);
            console.error("Error saving to collections:", error);
        }
    };

    const handleCreateCollection = async () => {
        if (collection_name.trim().length > 0) {
            try {
                setLoading(true);
                const response = await createCollection(collection_name);
                setCollections([...collections, response.data]);

                if (response.data !== null) {
                    onCollectionAdded();
                }

                setCollectionName('');
                fetchCollections();
                setView('list');
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error creating collection:", error);
            }
        }
    };

    return (
        <View style={styles.container}>
            {view === 'zero' && (
                <View style={styles.zeroContainer}>
                    <Image source={require('../../assets/zerobucket.png')} style={styles.zeroBucket} />
                    <Text style={styles.zeroTitle}>No saved items...yet 😉</Text>
                    <Text style={styles.zeroSubtitle}>Add some experiences to your bucket list!</Text>
                    <TouchableOpacity 
                        style={styles.createButton} 
                        onPress={() => setView('form')}
                    >
                        <Text style={styles.createButtonText}>+ Create collection</Text>
                    </TouchableOpacity>
                </View>
            )}

            {view === 'list' && (
                <View style={styles.listContainer}>
                    <View style={styles.bottomSheetHeader}>
                        <Text style={styles.bottomSheetTitle}>Save to a collection</Text>
                        <TouchableOpacity style={styles.closeButton} onPress={onClose}>
                            <Image source={require('../../assets/close.png')} style={styles.closeIcon} />
                        </TouchableOpacity>
                    </View>

                    {loading ? (
                        <ActivityIndicator size="large" color="#0000ff" />
                    ) : (
                        <FlatList
                            data={collections}
                            keyExtractor={(item) => item?.id.toString()}
                            renderItem={({ item }) => (
                                <TouchableOpacity 
                                    style={styles.collectionItem} 
                                    onPress={() => handleCheckboxToggle(item.id)}
                                >
                                    <Text style={styles.collectionText}>{item?.name}</Text>
                                    <View style={[styles.checkbox, selectedCollections.includes(item?.id) && styles.checkboxSelected]} />
                                </TouchableOpacity>
                            )}
                        />
                    )}

                    <TouchableOpacity
                        style={styles.createButton}
                        onPress={() => setView('form')}
                    >
                        <Text style={styles.createButtonText}>+ Create new collection</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[styles.button, selectedCollections.length > 0 ? styles.buttonActive : styles.buttonInactive]}
                        onPress={handleSaveToCollections}
                        disabled={selectedCollections.length === 0}
                    >
                        {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Save</Text>}
                    </TouchableOpacity>
                </View>
            )}

            {view === 'form' && (
                <View style={styles.formContainer}>
                    <View style={styles.bottomSheetHeader}>
                        <Text style={styles.bottomSheetTitle}>Create collection</Text>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setView(collections.length === 0 ? 'zero' : 'list')}>
                            <Image source={require('../../assets/close.png')} style={styles.closeIcon} />
                        </TouchableOpacity>
                    </View>

                    <Text style={styles.inputLabel}>Collection name</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="e.g., Vacation trip"
                        value={collection_name}
                        onChangeText={setCollectionName}
                    />

                    <TouchableOpacity
                        style={[styles.button, collection_name.trim().length > 0 ? styles.buttonActive : styles.buttonInactive]}
                        onPress={handleCreateCollection}
                        disabled={collection_name.trim().length === 0}
                    >
                        {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Create</Text>}
                    </TouchableOpacity>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    bottomSheetHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 50,
    },
    bottomSheetTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    closeIcon: {
        width: 30,
        height: 30,
    },
    zeroContainer: {
        alignItems: 'center',
        marginTop: 40,
    },
    zeroBucket: {
        width: 150,
        height: 150,
        marginBottom: 20,
    },
    zeroTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    zeroSubtitle: {
        fontSize: 16,
        color: '#666',
        marginBottom: 20,
    },
    createButton: {
        borderWidth: 1,
        borderColor: '#005FC5',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 20,
        marginBottom: 20,
    },
    createButtonText: {
        color: '#005FC5',
        fontSize: 16,
    },
    collectionItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomColor: '#E0E0E0',
        borderBottomWidth: 1,
    },
    collectionText: {
        fontSize: 16,
        color: '#333',
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 3,
    },
    checkboxSelected: {
        backgroundColor: '#005FC5',
        borderColor: '#005FC5',
    },
    inputLabel: {
        color: '#6C7681',
        marginBottom: 6,
    },
    input: {
        width: '100%',
        height: 50,
        borderColor: '#E0E0E0',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
        fontSize: 16,
        marginBottom: 20,
    },
    button: {
        width: '100%',
        height: 50,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10,
    },
    buttonActive: {
        backgroundColor: '#0066CC',
    },
    buttonInactive: {
        backgroundColor: '#E0E0E0',
    },
    buttonText: {
        fontSize: 18,
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
});

export default CollectionsManager;
