import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, KeyboardAvoidingView, Platform, ActivityIndicator, Alert, ScrollView, Keyboard, Image, FlatList } from 'react-native';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useNavigation } from '@react-navigation/native';
import BottomSheet from '../partials/BottomSheet';
import authService from '../services/authService';
import placesService from '../services/placesService'; // Adjust the path to your api file
import debounce from 'lodash.debounce';

const LocationInputScreen = () => {

  const [mapLoaded, setMapLoaded] = useState(false);
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'YOUR_GOOGLE_MAPS_API_KEY', // Add your API key
  });

  const handleMapLoad = () => {
    setMapLoaded(true);
  };
  
  const navigation = useNavigation();
  const [location, setLocation] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [locationResults, setLocationResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const getCurrentLocation = async () => {
      setLoading(true);
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setCurrentLocation({ latitude, longitude });
              setLocation('Accra, Ghana'); // Placeholder for actual location, reverse geocode if needed
              setLoading(false);
            },
            (error) => {
              Alert.alert('Error', 'Unable to fetch location.');
              setLoading(false);
            }
          );
        } else {
          Alert.alert('Error', 'Geolocation is not supported by this browser.');
          setLoading(false);
        }
      } catch (error) {
        console.error("Error getting location:", error);
        setLoading(false);
      }
    };
    
    getCurrentLocation();
  }, []);

  const handleContinue = async () => {
    try {
      setLoading(true);
      let response = await authService.updateUserLocation(location);

      if (response.success) {
        console.log("User updated successfully:", response.user);
        navigation.navigate('LoadingScreen');   // Navigate to the next screen
      } else {
        console.error("Error updating user:", response.error);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (searchQuery) => {
      if (searchQuery.length > 2) {
        setSearchLoading(true);
        try {
          const results = await placesService.searchLocations(searchQuery);
          setLocationResults(results);
        } catch (error) {
          console.error("Error searching locations:", error);
        } finally {
          setSearchLoading(false);
        }
      }
    }, 300),
    []
  );

  const handleLocationChange = (text) => {
    setLocation(text);
    debouncedSearch(text);
  };

  const renderBottomSheetContent = () => (
    <View style={styles.bottomSheetContent}>
      <View style={styles.bottomSheetHeader}>
        <Text style={styles.bottomSheetTitle}>Find your location</Text>
        <TouchableOpacity style={styles.closeButton} onPress={() => setBottomSheetVisible(false)}>
          <Image source={require('../assets/close.png')} style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <TextInput
        style={styles.input}
        placeholder="Search location"
        value={location}
        onChangeText={handleLocationChange}
        returnKeyType="search"
        onSubmitEditing={Keyboard.dismiss}
      />
      {searchLoading ? (
        <ActivityIndicator size="large" color="#0000ff" />
      ) : (
        <FlatList
          data={locationResults}
          keyExtractor={(item) => item.name}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => setLocation(item.name)}>
              <Text style={styles.locationSuggestion}>{item.name}</Text>
            </TouchableOpacity>
          )}
        />
      )}
    </View>
  );

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={15}
    >
      <View style={styles.wrapper}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between' }}
        keyboardShouldPersistTaps="handled"
      >
        <View>
          <Text style={styles.title}>What's your location?</Text>
          <Text style={styles.subtitle}>We’ll use this to personalize recommendations for you</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter location"
            value={location}
            onFocus={() => setBottomSheetVisible(true)}
            onChangeText={handleLocationChange}
            returnKeyType="done"
            onSubmitEditing={Keyboard.dismiss}
          />
          <View>
          {loading ? (
        <ActivityIndicator size="large" color="#0000ff" />
      ) : currentLocation && isLoaded ? (
        <GoogleMap
          mapContainerStyle={styles.map}
          center={{
            lat: currentLocation.latitude,
            lng: currentLocation.longitude,
          }}
          zoom={10}
          onLoad={handleMapLoad}
        >
          <Marker
            position={{
              lat: currentLocation.latitude,
              lng: currentLocation.longitude,
            }}
            label="Your Location"
          />
        </GoogleMap>
      ) : (
        <View style={styles.mapPlaceholder}>
          <Text style={styles.mapText}>Location not available</Text>
        </View>
      )}
          </View>
        </View>
      </ScrollView>
      <BottomSheet
        isVisible={bottomSheetVisible}
        onBackdropPress={() => setBottomSheetVisible(false)}
      >
        {renderBottomSheetContent()}
      </BottomSheet>
      {!bottomSheetVisible && <View style={styles.navigationContainer}>
        <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
          <Image source={require('../assets/back.png')} style={styles.backIcon} />
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.continueButton, location.length === 0 && styles.continueButtonDisabled]}
          onPress={handleContinue}
          disabled={location.length === 0}
        >
          {loading ? <ActivityIndicator size="small" color="#FFFFFF" /> : <Text style={styles.continueText}>I am ready!</Text>}
        </TouchableOpacity>
      </View>}
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 20,
    paddingTop: 90,
  },
  wrapper: {
    maxWidth: 600,
    width: '100%',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 14,
    color: '#6E6E6E',
    textAlign: 'left',
    marginBottom: 25,
  },
  input: {
    width: '100%',
    height: 50,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    fontSize: 16,
    marginBottom: 20,
  },
  map: {
    width: '100%',
    height: 300,
    borderRadius: 10,
  },
  mapPlaceholder: {
    width: '100%',
    height: 300,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#E0E0E0',
    borderWidth: 1,
  },
  mapText: {
    fontSize: 16,
    color: '#1A1A1A',
  },
  bottom: {
    padding: 0,
    marginBottom: 15,
    backgroundColor: '#FFFFFF',
  },
  button: {
    width: '100%',
    height: 50,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  buttonActive: {
    backgroundColor: '#0066CC',
  },
  buttonInactive: {
    backgroundColor: '#E0E0E0',
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  bottomSheetContent: {
    backgroundColor: 'white',
    padding: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  bottomSheetTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  closeButton: {
    padding: 5,
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  locationSuggestion: {
    fontSize: 16,
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  navigationContainer: {
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
  },
  backButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 50,
    borderColor: '#005FC5',
    backgroundColor: '#E6EFF9',
    borderWidth: 1,
    marginRight: 10,
    maxWidth: 70,
  },
  backIcon: {
    width: 20,
    height: 20,
  },
  backText: {
    fontSize: 16,
    color: '#1A1A1A',
  },
  continueButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 25,
    backgroundColor: '#0066CC',
  },
  continueButtonDisabled: {
    backgroundColor: '#E0E0E0',
  },
  continueText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default LocationInputScreen;
