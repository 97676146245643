// AppHeader.js
import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';

const AppHeader = ({ title, onGoBack, actions = [] }) => (
<View style={styles.header}>
          <TouchableOpacity onPress={() => {
            onGoBack();
          }} style={{ alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, height: 40, paddingHorizontal: 5, paddingTop: 12, width: '10%' }}>
            <Text style={{ color: '#ccc', fontSize: 12 }}>
              <Image source={require('../../assets/chevron-left-grey-bg.png')} style={{ width: 30, height: 30 }} />
            </Text>
          </TouchableOpacity>
          <Text style={styles.title}>{title}</Text>
          {actions.map((action, index) => (
          <TouchableOpacity onPress={() => {
            
          }}>
            <Text style={styles.saveButton}>Save</Text>
          </TouchableOpacity>
        ))}
        <View style={{ width: '10%' }}></View>
        </View>
);

const styles = StyleSheet.create({
  
    header: {
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        paddingTop: 50,
      },
      backButton: {
        fontSize: 24,
        color: '#1A1A1A',
      },
      title: {
        fontSize: 20,
        fontWeight: '600',
        color: '#1A1A1A',
      },
      saveButton: {
        fontSize: 16,
        color: '#005FC5',
      },
});

export default AppHeader;
