import React from 'react';
import { View, Text, StyleSheet, Image, ImageBackground } from 'react-native';

const TravelWithEase = () => {
    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <Text style={styles.heading}>
                    Travel with Ease, Explore with <Text style={styles.highlight}>Traaple</Text>
                </Text>
                <Text style={styles.description}>
                    Your ultimate travel companion is just a tap away. Simplify your journey with the Traaple app. Get personalized travel plans, trusted recommendations, and connect with fellow explorers—all in one easy-to-use platform.
                </Text>
                <View style={styles.qrContainer}>
                    <Image source={require('../assets/qr-code.png')} style={styles.qrCode} />
                </View>
            </View>
            <View style={styles.mockupContainer}>
                <Image
                    source={require('../assets/mockup.png')}
                    style={styles.mockupImage}
                />

            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#021B2D',
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 20,
        width: '100%',
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingRight: 10,
        width: 830,
        paddingLeft: 40,
        paddingVertical: 60,
    },
    heading: {
        color: '#FFFFFF',
        fontSize: 40,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    highlight: {
        color: '#00ADEF',
    },
    description: {
        color: '#FFFFFF',
        fontSize: 14,
        lineHeight: 22,
        marginBottom: 20,
    },
    qrContainer: {
        width: 130,
        height: 130,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    qrCode: {
        width: 120,
        height: 120,
    },
    mockupContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginLeft: 10,
    },
    mockupImage: {
        width: 500,
        height: 430,
        resizeMode: 'contain',
        bottom: 0,
        right: 0,
    },
    secondMockup: {
        marginLeft: -50,
    },
});

export default TravelWithEase;
