import React, { useState, useEffect } from 'react';
import { Text, Image } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import AsyncStorage from './services/AsyncStorage';

// Import screens
import BucketListScreen from './screens/BucketListScreen';
import CollectionsScreen from './screens/CollectionsScreen';
import QuickGlanceScreen from './screens/QuickGlanceScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import CommunityWelcomeScreen from './screens/community/Welcome';
import CommunityFeed from './screens/community/Feed';

const Tab = createBottomTabNavigator();

const BottomTabNavigator = () => {
  const [communityOnboarded, setCommunityOnboarded] = useState(null);

  const checkOnboardingStatus = async () => {
    const onboarded = await AsyncStorage.getItem('community_onboarded');
    setCommunityOnboarded(onboarded);
  };

  useFocusEffect(
    React.useCallback(() => {
      checkOnboardingStatus();
    }, [])
  );

  useEffect(() => {
    checkOnboardingStatus();
  });

  const CommunityComponent = communityOnboarded === '1' ? CommunityFeed : CommunityWelcomeScreen;

  return (
    <Tab.Navigator screenOptions={{ tabBarActiveTintColor: '#005FC5' }}>
      <Tab.Screen 
        name="QuickGlance" 
        component={QuickGlanceScreen} 
        options={{
          title: 'Quick Glance', 
          headerShown: false,
          tabBarShowLabel: true,
          tabBarLabel: ({focused, color}) => (
            <Text style={{color: focused ? '#005FC5' : '#B0B4B7', fontFamily:'Urbanist-Regular', fontSize: 11}}>Discover</Text>
          ),
          tabBarIcon:({focused, color}) => (
            focused ? <Image source={require('./assets/discover.png')} style={{ width: 18, height: 18, tintColor: '#005FC5' }} /> : <Image source={require('./assets/discover.png')} style={{ width: 18, height: 18 }} />
          ),
        }} 
      />
      <Tab.Screen 
        name="BucketList" 
        component={CollectionsScreen}
        options={{ 
          title: 'Bucket List',
          unmountOnBlur: true,
          headerShown: false,
          tabBarShowLabel: true,
          tabBarLabel: ({focused, color}) => (
            <Text style={{color: focused ? '#005FC5' : '#B0B4B7', fontFamily:'Urbanist-Regular', fontSize: 11}}>Bucket List</Text>
          ),
          tabBarIcon:({focused, color}) => (
            focused ? <Image source={require('./assets/bucketlist.png')} style={{ width: 18, height: 18, tintColor: '#005FC5' }} /> : <Image source={require('./assets/bucketlist.png')} style={{ width: 18, height: 18 }} />
          ),
        }}
      />
      <Tab.Screen 
        name="Community" 
        component={CommunityComponent} 
        options={{ 
          title: 'Community',
          headerShown: false,
          tabBarShowLabel: true,
          tabBarLabel: ({focused, color}) => (
            <Text style={{color: focused ? '#005FC5' : '#B0B4B7', fontFamily:'Urbanist-Regular', fontSize: 11}}>Community</Text>
          ),
          tabBarIcon:({focused, color}) => (
            focused ? <Image source={require('./assets/community.png')} style={{ width: 18, height: 18, tintColor: '#005FC5' }} /> : <Image source={require('./assets/community.png')} style={{ width: 18, height: 18 }} />
          ),
        }} 
      />
      <Tab.Screen 
        name="Profile" 
        component={UserProfileScreen}
        options={{ 
          title: 'Profile',
          headerShown: false,
          tabBarShowLabel: true,
          tabBarLabel: ({focused, color}) => (
            <Text style={{color: focused ? '#005FC5' : '#B0B4B7', fontFamily:'Urbanist-Regular', fontSize: 11}}>Profile</Text>
          ),
          tabBarIcon:({focused, color}) => (
            focused ? <Image source={require('./assets/user.png')} style={{ width: 18, height: 18, tintColor: '#005FC5' }} /> : <Image source={require('./assets/user.png')} style={{ width: 18, height: 18 }} />
          ),
        }} 
      />
    </Tab.Navigator>
  );
};

export default BottomTabNavigator;
