import axios from 'axios';
import AsyncStorage from '../services/AsyncStorage';

const API_URL = 'https://traaple-api-su1z.onrender.com/api/collections'; // INPUT_REQUIRED {API base URL}

const getHeaders = async () => {
  const token = await AsyncStorage.getItem('userToken');
  if (!token) {
    console.error('No token found');
    throw new Error('Authentication token is not available. Please login again.');
  }
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export const createCollection = async (name) => {
  try {
    const headers = await getHeaders();
    const response = await axios.post(API_URL + '/create', { name }, { headers });
    console.log('Collection created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating collection:', error.response ? error.response.data : error);
    throw error;
  }
};

export const getCollections = async () => {
  try {
    const headers = await getHeaders();
    const response = await axios.get(API_URL + '/list', { headers });
    console.log('Collections fetched successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error fetching collections:', error.response ? error.response.data : error);
    throw error;
  }
};

export const updateCollection = async (id, data) => {
  try {
    const headers = await getHeaders();
    const response = await axios.put(`${API_URL}/${id}`, data, { headers });
    console.log('Collection updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating collection:', error.response ? error.response.data : error);
    throw error;
  }
};

export const deleteCollection = async (id) => {
  try {
    const headers = await getHeaders();
    const response = await axios.delete(`${API_URL}/delete/${id}`, { headers });
    console.log('Collection deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting collection:', error.response ? error.response.data : error);
    throw error;
  }
};