import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, Image } from 'react-native';
import ContentLoader from 'react-content-loader';
import { useNavigation } from '@react-navigation/native';

const LoadingScreen = () => {
  const navigation = useNavigation();

  useEffect(() => {
    // random delay between 3 - 5 seconds
    const delay = Math.floor(Math.random() * 2000) + 3000;
    setTimeout(() => {
      navigation.navigate('QuickGlanceScreen');
    }, delay);
  }, []);

  return (
    <View style={styles.container}>
      <Image source={require('../assets/Loader.png')} style={styles.image} />
      
      {/* Use a pre-defined loader like Facebook */}
      <ContentLoader viewBox="0 0 400 160" height={160} width={400}>
        {/* You can customize the loader's structure */}
        <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
      </ContentLoader>

      <Text style={styles.text}>Building your experiences...</Text>
    </View>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  image: {
    width: 250,
    height: 200,
    resizeMode: 'contain',
  },
  text: {
    fontSize: 18,
    color: '#6E6E6E',
    marginTop: 20,
  },
});

export default LoadingScreen;
