import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from 'react-native';

const Header = ({ navigation, loggedIn = false }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const renderLinks = () => (
    <>
      {loggedIn ? (
        <>
          <TouchableOpacity onPress={() => navigation.navigate('QuickGlanceScreen')}>
            <Text style={styles.mobileMenuLink}>Discover</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('CommunityWelcomeScreen')}>
            <Text style={styles.mobileMenuLink}>Community</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('BucketListScreen')}>
            <Text style={[styles.mobileMenuLink, { color: '#005FC5' }]}>Bucket list</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('UserProfileScreen')}>
            <Text style={styles.mobileMenuLink}>Profile</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <TouchableOpacity onPress={() => window.open('https://about.traaple.com/', '_self')}>
            <Text style={styles.mobileMenuLink}>About</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => window.open('https://about.traaple.com/providers.html', '_self')}>
            <Text style={styles.mobileMenuLink}>Service Providers</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => window.open('https://about.traaple.com/features.html', '_self')}>
            <Text style={styles.mobileMenuLink}>Features</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => window.open('https://about.traaple.com/faq.html', '_self')}>
            <Text style={styles.mobileMenuLink}>FAQs</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => window.open('https://about.traaple.com/#get-the-app', '_self')} style={styles.getTheApp}>
            <Image source={require('../assets/smartphone.png')} style={styles.smartphoneIcon} />
            <Text style={styles.mobileMenuLink}>Get the app</Text>
          </TouchableOpacity>
        </>
      )}
    </>
  );

  return (
    <View style={styles.container}>
      {/* Logo */}
      <View style={styles.logoContainer}>
        <Image source={require('../assets/traaple-logo.png')} style={styles.logo} />
      </View>

      {/* Desktop */}
      {!isMobile && (
        <View style={styles.rightContainer}>
          <View style={styles.linksContainer}>
            {renderLinks()}
          </View>
          {loggedIn ? (
            <TouchableOpacity onPress={() => navigation.navigate('UserProfileScreen')}>
              <Image source={require('../assets/user.png')} style={styles.profileIcon} />
            </TouchableOpacity>
          ) : (
            <View style={styles.authButtons}>
              <TouchableOpacity style={styles.loginButton} onPress={() => navigation.navigate('LoginScreen')}>
                <Text style={styles.loginText}>Login</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.signupButton} onPress={() => navigation.navigate('SignupScreen')}>
                <Text style={styles.signupText}>Sign up</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {/* Mobile Hamburger */}
      {isMobile && (
        <View style={styles.hamburgerWrapper}>
          <TouchableOpacity onPress={toggleMenu}>
            <View style={styles.hamburgerIcon}>
              <View style={styles.bar} />
              <View style={styles.bar} />
              <View style={styles.bar} />
            </View>
          </TouchableOpacity>

          {menuOpen && (
            <View style={styles.mobileMenu}>
              {renderLinks()}
              {!loggedIn && (
                <View style={styles.mobileMenuButton}>
                  <TouchableOpacity style={styles.loginButton} onPress={() => navigation.navigate('LoginScreen')}>
                    <Text style={styles.loginText}>Login</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.signupButton} onPress={() => navigation.navigate('SignupScreen')}>
                    <Text style={styles.signupText}>Sign up</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingVertical: 10,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 160,
    height: 30,
    resizeMode: 'contain',
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linksContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
    flexWrap: 'wrap',
    gap: 16,
  },
  profileIcon: {
    width: 24,
    height: 24,
  },
  authButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  loginButton: {
    borderWidth: 1,
    borderColor: '#005FC5',
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginHorizontal: 5,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginText: {
    fontSize: 16,
    color: '#005FC5',
  },
  signupButton: {
    backgroundColor: '#005FC5',
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  signupText: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  hamburgerWrapper: {
    position: 'relative',
  },
  hamburgerIcon: {
    width: 25,
    justifyContent: 'space-between',
    height: 18,
    paddingVertical: 2,
  },
  bar: {
    height: 3,
    backgroundColor: '#333',
    borderRadius: 2,
  },
  mobileMenu: {
    position: 'absolute',
    top: 60,
    left: 0,
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderTopWidth: 1,
    borderTopColor: '#E6E6E6',
    paddingVertical: 20,
    paddingHorizontal: 24,
    zIndex: 1000,
    elevation: 5,
  },
  mobileMenuLink: {
    fontSize: 13,
    color: '#333',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#F0F0F0',
  },
  mobileMenuButton: {
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
  },
  smartphoneIcon: {
    width: 15,
    height: 15,
  },
  getTheApp: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
});

export default Header;
