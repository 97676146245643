import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import authService from '../services/authService';

const VerifyEmailScreen = ({ route }) => {
  const { email } = route.params;
  const navigation = useNavigation();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let user_profile = await getUserProfile();

      if (user_profile?.verified) {
        console.log('User is verified');
        clearInterval(intervalId); // Clear the interval once verified
        navigation.navigate('UsernameInputScreen');
      }
    }, 5000);

    return () => clearInterval(intervalId); // Clear the interval when component unmounts
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await authService.getUserProfile();
      if (response) {
        console.log('User profile:', response.user);
        return response.user;
      } else {
        console.log('User profile not found');
        throw new Error('User profile not found');
      }
    } catch (error) {
      console.error('Error getting user profile:', error);
      throw error;
    }
  };

  const handleGoToEmail = () => {
    const emailUrl = `mailto:${email}`;
    const emailDomain = email.split('@')[1];
    const emailWebUrl = `https://${emailDomain}`;

    Linking.canOpenURL(emailUrl)
      .then((supported) => {
        if (supported) {
          Linking.openURL(emailUrl);
        } else {
          return Linking.openURL(emailWebUrl);
        }
      })
      .catch((err) => {
        console.error('An error occurred', err);
        // Navigate to another screen if neither the email client nor the web URL can be opened
        navigation.navigate('UsernameInputScreen');
      });
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>

      <Image source={require('../assets/auth/mail.png')} style={styles.icon} />
      <Text style={styles.title}>Verify your email</Text>
      <Text style={styles.subtitle}>
        We have sent a verification link to 
        <Text style={styles.email}> {email}.</Text> 
        Click that link to complete verifying your account
      </Text>
      <Text style={styles.link} onPress={handleGoToEmail}>
        Go to email
      </Text>
      </View>
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 40,
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 100
  },
  icon: {
    width: 60,
    height: 60,
    marginBottom: 20,
    alignSelf: 'center',
    marginTop: 30
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 24,
  },
  email: {
    fontWeight: 'bold',
    color: '#1A1A1A',
  },
  link: {
    fontSize: 16,
    color: '#0066CC',
    textDecorationLine: 'underline',
    alignSelf: 'center',
  },
});

export default VerifyEmailScreen;
