import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const PaymentSuccessfulScreen = () => {
  const navigation = useNavigation();

  const handleViewDetails = () => {
    // Navigate to the details screen
    navigation.navigate('DetailsScreen'); // Replace 'DetailsScreen' with your details screen's name
  };

  const handleExploreMore = () => {
    // Navigate to the explore more experiences screen
    navigation.navigate('ExploreScreen'); // Replace 'ExploreScreen' with your explore screen's name
  };

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        {/* <Image source={require('../assets/success.png')} style={styles.icon} /> */}
      </View>
      <Text style={styles.title}>Payment successful</Text>
      <Text style={styles.message}>
        Your experience has been successfully booked! Get ready to go Hiking!
      </Text>
      <TouchableOpacity style={styles.button} onPress={handleViewDetails}>
        <Text style={styles.buttonText}>View details</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.linkButton} onPress={handleExploreMore}>
        <Text style={styles.linkButtonText}>Explore more experiences</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    marginBottom: 20,
  },
  icon: {
    width: 80,
    height: 80,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 10,
  },
  message: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
    marginBottom: 40,
  },
  button: {
    backgroundColor: '#0066CC',
    borderRadius: 25,
    paddingVertical: 15,
    paddingHorizontal: 40,
    alignItems: 'center',
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  linkButton: {
    alignItems: 'center',
  },
  linkButtonText: {
    fontSize: 16,
    color: '#0066CC',
    textDecorationLine: 'underline',
  },
});

export default PaymentSuccessfulScreen;
