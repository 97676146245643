import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList, Image, Dimensions, ActivityIndicator, Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import authService from '../services/authService';

const interests = [
  { id: '1', name: 'Activities', image: require('../assets/interests/activities.png'), inactive_image: require('../assets/interests/activities-gray.png') },
  { id: '2', name: 'Attractions', image: require('../assets/interests/attractions.png'), inactive_image: require('../assets/interests/attractions-gray.png') },
  { id: '3', name: 'Tours', image: require('../assets/interests/tours.png'), inactive_image: require('../assets/interests/tours-gray.png') },
  { id: '4', name: 'Events', image: require('../assets/interests/events.png'), inactive_image: require('../assets/interests/events-gray.png') },
  { id: '5', name: 'Nightlife', image: require('../assets/interests/nightlife.png'), inactive_image: require('../assets/interests/nightlife-gray.png') },
  { id: '6', name: 'Restaurants', image: require('../assets/interests/restaurants.png'), inactive_image: require('../assets/interests/restaurants-gray.png') },
];

const InterestsSelectionScreen = () => {
  const navigation = useNavigation();
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleInterestSelection = (interest) => {
    setSelectedInterests((prev) => {
      if (prev.includes(interest)) {
        return prev.filter((item) => item !== interest);
      } else {
        return [...prev, interest];
      }
    });
  };

  const isItemSelected = (id) => selectedInterests.includes(id);

  const handleContinue = async () => {
    console.log("Selected Interests:", selectedInterests);
    try {
      setLoading(true);
      let response = await authService.updateUserInterests(JSON.stringify(selectedInterests));

      if (response.success) {
        setLoading(false);
        navigation.navigate('LocationInputScreen');
      } else {
        setLoading(false);
        console.error("Error updating user:", response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating user:", error);
    }
  };

  const wrapperWidth = Math.min(Dimensions.get('window').width * 0.5, 600);
  const numColumns = 3;
  const itemSpacing = 5;
  const itemWidth = Math.floor((wrapperWidth - itemSpacing * (numColumns - 1)) / numColumns);

  return (
    <View style={styles.container}>
      <View style={[styles.wrapper, { width: wrapperWidth }]}>
        <Text style={styles.title}>What experiences are you interested in?</Text>
        <Text style={styles.subtitle}>
          We’ll use this information to personalize recommendations for you
        </Text>
        <FlatList
          data={interests}
          numColumns={numColumns}
          columnWrapperStyle={styles.columnWrapperStyle}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => toggleInterestSelection(item.id)}>
              <View style={[styles.experienceItem, { width: itemWidth, height: itemWidth }]}>
                <Image
                  source={isItemSelected(item.id) ? item.image : item.inactive_image}
                  style={styles.experienceImage}
                />
                <View style={styles.overlay(isItemSelected(item.id))}>
                  <Text style={styles.experienceName}>{item.name}</Text>
                </View>
              </View>
            </TouchableOpacity>
          )}
          contentContainerStyle={styles.flatListContent}
        />
        <View style={styles.navigationContainer}>
          <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
            <Image source={require('../assets/back.png')} style={styles.backIcon} />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.continueButton, selectedInterests.length === 0 && styles.continueButtonDisabled]}
            onPress={handleContinue}
            disabled={selectedInterests.length === 0}
          >
            {loading ? <ActivityIndicator size="small" color="#FFFFFF" /> : <Text style={styles.continueText}>Continue</Text>}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#FFFFFF',
  },
  wrapper: {
    maxWidth: 600,
    width: '100%',
    marginHorizontal: 'auto',
    alignSelf: 'center',
  },
  title: {
    fontSize: 26,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
    marginTop: 50,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 17,
    color: '#6E6E6E',
    marginBottom: 20,
    textAlign: 'left',
  },
  columnWrapperStyle: {
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  experienceItem: {
    marginBottom: 5,
    borderRadius: 15,
    overflow: 'hidden',
  },
  experienceImage: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
    resizeMode: 'cover',
  },
  overlay: (isSelected) => ({
    ...StyleSheet.absoluteFillObject,
    backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  experienceName: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flatListContent: {
    paddingBottom: 100,
  },
  navigationContainer: {
    flexDirection: 'row',
    marginTop: 20,
  },
  backButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 50,
    borderColor: '#005FC5',
    backgroundColor: '#E6EFF9',
    borderWidth: 1,
    marginRight: 10,
    maxWidth: 70,
  },
  backIcon: {
    width: 20,
    height: 20,
  },
  continueButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 25,
    backgroundColor: '#0066CC',
  },
  continueButtonDisabled: {
    backgroundColor: '#E0E0E0',
  },
  continueText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default InterestsSelectionScreen;
