import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation, CommonActions } from '@react-navigation/native';
import authService from '../services/authService';
import * as WebBrowser from 'expo-web-browser';


const UserProfileScreen = () => {
  const [profileImage, setProfileImage] = useState(require('../assets/auth/avatar1.png'));
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [email, setEmail] = useState('');

  useFocusEffect(
    useCallback(() => {
      checkLoggedIn();

      
    }, [])
  );

  const openAboutLink = async () => {
    let result = await WebBrowser.openBrowserAsync('https://traaple.com');
    console.log(result);
  };


  const fetchFollowCounts = async () => {
    try {
      console.log("TESTING FOLLOWER COUNT")
      const response = await authService.getFollowCounts(userId);
      console.log("THE FOLLOWER COUNT");
      console.log(response);
      setFollowersCount(response.followersCount);
      setFollowingCount(response.followingCount);
    } catch (error) {
      console.error('Error fetching follow counts:', error);
    }
  };

  useEffect(() => {
    // getUserProfile from authService

    const getUserProfile = async () => {
      try {
        const response = await authService.getUserProfile();
        if (response) {
          console.log('User profile:', response.user);
          console.log("User ID: ", response.user?.id);
          
          
          setUserId(response.user?.id);
          setUsername(response.user?.username);
          setEmail(response.user?.email);
        } else {
          console.log('User profile not found');
        }
      } catch (error) {
        console.error('Error getting user profile:', error);
      }
    };
    getUserProfile();

  }, []);


  useEffect(() => {
    fetchFollowCounts();
  }, [userId])

  const checkLoggedIn = async () => {
    try {
      const response = await authService.checkLoggedIn();
      if (response) {
        console.log('User is logged in');
      } else {
        console.log('User is not logged in');
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'QuickGlanceScreen' }],
          })
        );
    
        // Use a timeout to ensure the reset action is processed before navigating
        setTimeout(() => {
          navigation.navigate('LoginScreen');
        }, 0);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };


  const logOut = () => {
    // Handle logout action
    console.log('Logged out');
    // authService logout

    const response = authService.logout();
    if (response) {
      console.log('User logged out successfully');
      
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'QuickGlanceScreen' }],
        })
      );
  
      // Use a timeout to ensure the reset action is processed before navigating
      setTimeout(() => {
        navigation.navigate('LoginScreen');
      }, 0);
    } else {
      console.log('User logout failed');
    }

  };

  const navigation = useNavigation();
  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Image
          style={styles.profileImage}
          source={profileImage} // Replace with actual image source
        />
        <Text style={styles.profileName}>{username}</Text>
        <View style={styles.followInfo}>
          <View>
            <Text style={styles.followNum}>{followersCount}</Text>
            <Text style={styles.followText}>Followers</Text>
          </View>
          <View>
            <Text style={styles.followNum}>{followingCount}</Text>
          <Text style={styles.followText}>Following</Text>
          </View>
        </View>
      </View>
      <TouchableOpacity style={styles.menuItem} onPress={() => navigation.navigate('UserProfileEditScreen')}>
        <Text style={styles.menuText}>Personal Information</Text>
        <Image source={require('../assets/chevron-right.png')} style={styles.chevron} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.menuItem} onPress={() => navigation.navigate('EmptyExperienceScreen')}>
        <Text style={styles.menuText}>Upcoming</Text>

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <View style={styles.notification}>
          <Text style={styles.notificationText}>0</Text>
        </View>
        <Image source={require('../assets/chevron-right.png')} style={styles.chevron} />
        </View>
        
      </TouchableOpacity>
      <TouchableOpacity style={styles.menuItem} onPress={() => navigation.navigate('EmptyExperienceScreen')}>
        <Text style={styles.menuText}>Past</Text>
        <Image source={require('../assets/chevron-right.png')} style={styles.chevron} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.menuItem} onPress={() => navigation.navigate('ProfileScreen', { userId: userId })}>
        <Text style={styles.menuText}>Community Posts</Text>
        <Image source={require('../assets/chevron-right.png')} style={styles.chevron} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.menuItem} onPress={() => navigation.navigate('ShareFeedbackScreen')}>
        <Text style={styles.menuText}>Share feedback</Text>
        <Image source={require('../assets/chevron-right.png')} style={styles.chevron} />
      </TouchableOpacity>
      
      <TouchableOpacity style={styles.menuItem} onPress={() => {openAboutLink()}}>
        <Text style={styles.menuText}>About Traaple</Text>
        <Image source={require('../assets/external-link.png')} style={styles.chevron} />
      </TouchableOpacity>

      <TouchableOpacity style={[styles.menuItem, {marginBottom: 100}]} onPress={logOut}>
        <Text style={styles.menuText}>Logout</Text>
        <Image source={require('../assets/logout.png')} style={styles.chevron} />
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingTop: 50,
  },
  header: {
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#F9F9F9',
    marginBottom: 20,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 5,
    marginBottom: 10,
  },
  profileName: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#1A1A1A',
    marginTop: 10,
  },
  followInfo: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '60%',
    marginTop: 20,
    gap: 10,
  },
  followText: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
  },
  followNum: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1A1A1A',
    textAlign: 'center',
  },
  menuItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#F9F9F9',
    marginBottom: 10,
    borderRadius: 10,
    marginHorizontal: 10,
  },
  menuText: {
    fontSize: 18,
    color: '#1A1A1A',
  },
  notification: {
    backgroundColor: '#E0E0E0',
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  notificationText: {
    fontSize: 14,
    color: '#1A1A1A',
  },
  chevron: {
    width: 22,
    height: 22,
  },
});

export default UserProfileScreen;
