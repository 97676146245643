const ImagePicker = {
    launchImageLibraryAsync: async () => {
      return new Promise((resolve, reject) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        
        input.onchange = () => {
          const file = input.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              resolve({ uri: reader.result });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          } else {
            reject('No file selected');
          }
        };
        
        input.click();
      });
    },
  };
  
  export default ImagePicker;
  