import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, ActivityIndicator, TextInput, Share } from 'react-native';
// import MasonryList from 'react-native-masonry-list';
import BottomSheet from '../partials/BottomSheet';
import { getCollections } from '../services/collectionService';
import { useNavigation } from '@react-navigation/native';
import { createCollection } from '../services/collectionService';
import CollectionsManager from '../components/organisms/CollectionsManager';

export default function BucketListScreen() {
  const navigation = useNavigation();
  const [collection_name, setCollectionName] = useState('');
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state


  const hideBottomSheet = () => {
    setBottomSheetVisible(false);
};

  useEffect(() => {
    getCollectionsList();
  }, []);


  const handleCreateCollection = async () => {
    if (collection_name.trim().length > 0) {
        try {
            setLoading(true);
            let response = await createCollection(collection_name);


            setLoading(false);
            console.log('Collection created successfully!');
            console.log(response);


            hideBottomSheet();

        } catch (error) {
            setLoading(false);
            console.error("Error updating user:", error);
        }
    }
};

  const getCollectionsList = async () => {
    try {
      const response = await getCollections();
      console.log('Collections successfully:', response.data);
      
      setCollections(response.data);
    } catch (error) {
      console.error('Error fetching collections:', error.response ? error.response.data : error);
      throw error;
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <ActivityIndicator size="large" style={styles.loader} />
      ) : collections?.length === 0 ? (
        <View style={styles.zeroContainer}>
          <Text style={styles.zeroTitle}>No saved items...yet 😉</Text>
          <Text style={styles.zeroSubtitle}>Add some experiences to your bucket list!</Text>
          <Image source={require('../assets/zerobucket.png')} style={styles.zeroBucket} />
        </View>
      ) : (
        <>
          <View style={styles.header}>
            <Text style={styles.title}>My Bucket List</Text>
            <TouchableOpacity style={styles.fab} onPress={() => {
              setBottomSheetVisible("CollectionForm");
            }}>
              <Text style={styles.fabIcon}>+</Text>
            </TouchableOpacity>
          </View>
          {/* <MasonryList
            onPressImage={(data) => {
              console.log(data);
              navigation.navigate('BucketListScreen', { collectionId: data.id, collectionName: data.title });
            }}
            images={collections?.map(item => ({
              uri: item.image ? item.image : Image.resolveAssetSource(require('../assets/avatarbucket.png')).uri,
              title: item.name,
              items: item.item_count,
              id: item.id,
            }))}
            columns={2}
            renderIndividualFooter={(data) => (
              <View style={styles.itemFooter}>
                <Text style={styles.itemTitle}>{data.title}</Text>
                <Text style={styles.itemCount}>{data.items} items</Text>
              </View>
            )}
            imageContainerStyle={styles.itemContainer}
            spacing={5}
          /> */}



      {bottomSheetVisible === "CollectionForm" && (
                <BottomSheet isVisible={bottomSheetVisible} onHide={hideBottomSheet}>

<CollectionsManager 
                        onClose={() => setBottomSheetVisible(false)}  // Pass a callback to close the form
                        onCollectionAdded={getCollectionsList}  // Pass a callback to refresh the list
                    />

                    {/* <View style={styles.bottomSheetContent}>
                        <View style={styles.bottomSheetHeader}>
                            <Text style={styles.bottomSheetTitle}>Create collection</Text>
                            <TouchableOpacity style={styles.closeButton} onPress={hideBottomSheet}>
                                <Image source={require('../assets/close.png')} style={styles.closeIcon} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.Collectionsform}>
                            <Text style={styles.inputLabel}>Collection name</Text>
                            <TextInput
                                style={styles.input}
                                placeholder="eg. Vacation trip"
                                value={collection_name}
                                onChangeText={setCollectionName}
                            />

                            <TouchableOpacity
                                style={[styles.button, collection_name.trim().length > 0 ? styles.buttonActive : styles.buttonInactive]}
                                onPress={handleCreateCollection}
                                disabled={collection_name.trim().length === 0}
                            >
                                {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Create</Text>}
                            </TouchableOpacity>
                        </View>
                    </View> */}
                </BottomSheet>
            )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 0,
    paddingBottom: 16,
    backgroundColor: '#fff',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 60,
    marginBottom: 8,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 25,
    fontWeight: '600',
  },
  fab: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#005fC5',
    borderRadius: 20,
  },
  fabIcon: {
    fontSize: 20,
    color: 'white',
  },
  itemContainer: {
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: '#f8f8f8',
    marginBottom: 8,
  },
  itemFooter: {
    padding: 8,
  },
  itemTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  itemCount: {
    fontSize: 14,
    color: '#888',
  },
  zeroContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 40,
    marginTop: 200,
  },
  zeroTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
  },
  zeroSubtitle: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
    marginBottom: 20,
  },
  zeroBucket: {
    width: 250,
    height: 250,
    marginBottom: 20,
  },



  bottomSheetContent: {
    padding: 10,
},



bottomSheetTitle: {
    fontSize: 20,
    fontWeight: 'bold',
},
closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
},
closeIcon: {
    width: 30,
    height: 30,
},
noCollections: {
    alignItems: 'center',
    marginTop: 40,
},
starIcon: {
    width: 70,
    height: 70,
    marginBottom: 20,
},
noCollectionsText: {
    fontSize: 18,
    color: '#666',
    marginBottom: 20,
},
createButton: {
    borderWidth: 1,
    borderColor: '#005FC5',
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
},
createButtonText: {
    color: '#005FC5',
    fontSize: 16,
},


button: {
  width: '100%',
  height: 50,
  borderRadius: 25,
  alignItems: 'center',
  justifyContent: 'center',
  marginVertical: 10,
},
buttonActive: {
  backgroundColor: '#0066CC',
},
buttonInactive: {
  backgroundColor: '#E0E0E0',
},
buttonText: {
  fontSize: 18,
  color: '#FFFFFF',
  fontWeight: 'bold',
},

bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 50,
},

input: {
    width: '100%',
    height: 50,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    fontSize: 16,
    marginBottom: 20,
},
});
