// components/atoms/ProductPrice.js
import React, { useState, useEffect } from 'react';
import { Text, StyleSheet } from 'react-native';
import { displayPrice } from '../../utils/displayPrice';

const ProductPrice = ({ amount, fontSize = 16, fontWeight = 'normal', color = '#000' }) => {
    const [formattedPrice, setFormattedPrice] = useState('');

    useEffect(() => {
        const fetchAndDisplayPrice = async () => {
            const price = await displayPrice(amount);
            setFormattedPrice(price);
        };

        fetchAndDisplayPrice();
    }, [amount]);

    return (
        <Text style={[styles.price, { fontSize, fontWeight, color }]}>
            {formattedPrice}
        </Text>
    );
};

const styles = StyleSheet.create({
    price: {
        fontFamily: 'System', // You can customize the font family here
    },
});

export default ProductPrice;
