import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, ImageBackground, Image, TextInput, RefreshControl } from 'react-native';
import { searchExperiences } from '../services/experienceService';
import { useRoute } from '@react-navigation/native';

const ExperienceListScreen = ({ navigation }) => {
  const [experiences, setExperiences] = useState([]);
  const [text, onChangeText] = useState('');

  const route = useRoute();
  const { query } = route.params || {};

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    try {
      let response = await searchExperiences(query);
      console.log('Experiences fetched successfully:', response.experiences);
      setExperiences(response.experiences);
    } catch (error) {
      console.error('Error fetching collections:', error);
      console.log(error.response ? error.response.data : error.message);
    } finally {
      setRefreshing(false);  // End refreshing
    }
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.item}
      activeOpacity={0.9}
      onPress={() => navigation.navigate('ExperienceDetailScreen', { item })}
    >
      <ImageBackground source={{ uri: item.image }} style={styles.singleHandpickedImage} imageStyle={{ borderRadius: 10, width: '100%' }}>
        <View style={styles.badge}>
          <Image source={require('../assets/fav.png')} style={{ width: 30, height: 30 }} />
        </View>

        <View style={{ backgroundColor: '#000', borderRadius: 5, padding: 8, position: 'absolute', bottom: 10, left: 10 }}>
          <Text style={{ color: '#fff', fontSize: 15 }}>
            $ {item.price}
          </Text>
        </View>
      </ImageBackground>

      <View style={{ marginBottom: 10 }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{item.title}</Text>
      </View>


      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image source={require('../assets/tflag.png')} style={{ width: 15, height: 15, marginRight: 5 }} />
        <Text style={{ fontSize: 14 }}>{item.location}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20, paddingHorizontal: 20 }}>
        <Text style={{ fontSize: 16 }}>Search results</Text>
        <TouchableOpacity onPress={() => navigation.navigate('FilterScreen')} style={{ flexDirection: 'row', borderWidth: 1, borderColor: '#005FC5', borderRadius: 50, padding: 10 }}>
          <Image source={require('../assets/filter.png')} style={{ width: 20, height: 20, marginRight: 5 }} />
          <Text style={{ fontSize: 16, color: '#005FC5' }}>Add filters</Text>
        </TouchableOpacity>
      </View>

      <FlatList
  data={experiences}
  keyExtractor={(item) => item.id.toString()}
  renderItem={renderItem}
  numColumns={4} // Render in 3 columns
  columnWrapperStyle={styles.columnWrapper} // Style for the row
  contentContainerStyle={styles.scrollContainer}
  refreshControl={<RefreshControl refreshing={refreshing} onRefresh={fetchExperiences} />}
/>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingTop: 40
  },
  scrollContainer: {
    padding: 10,
  },
  columnWrapper: {
    justifyContent: 'flex-start', // Space out items evenly in a row
  },
  item: {
    flexBasis: '25%', // Approximately one-third of the container
    marginBottom: 20,
    marginHorizontal: 5, // Adjust spacing between items
  },
  singleHandpickedImage: {
    width: '100%',
    height: 230,
    borderRadius: 10,
    marginBottom: 10,
  },
  badge: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});


export default ExperienceListScreen;
