const AsyncStorage = {
    getItem: async (key) => {
      try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
      } catch (error) {
        console.error('Error getting item from AsyncStorage:', error);
        return null;
      }
    },
  
    setItem: async (key, value) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error('Error setting item in AsyncStorage:', error);
      }
    },
  
    removeItem: async (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing item from AsyncStorage:', error);
      }
    },
  
    clear: async () => {
      try {
        localStorage.clear();
      } catch (error) {
        console.error('Error clearing AsyncStorage:', error);
      }
    },
  };
  
  export default AsyncStorage;
  