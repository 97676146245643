import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, KeyboardAvoidingView, Platform, ActivityIndicator, Alert, ScrollView, Keyboard } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import authService from '../services/authService';

const UsernameInputScreen = () => {
  const navigation = useNavigation();
  const [username, setUsername] = useState('');

  const [loading, setLoading] = useState(false);  // Loader state

  const handleContinue = async () => {
    if (username.trim().length > 0) { 
      try {
        setLoading(true);
        let response = await authService.updateUser(username);

        if (response.success) {
          console.log("User updated successfully:", response.user);
          navigation.navigate('ProfileImageScreen', { username: username });
        } else {
          console.error("Error updating user:", response.error);
        }

      } catch (error) {
        setLoading(false);
        console.error("Error updating user:", error);
      }
    }
  };

  return (
    <KeyboardAvoidingView 
      style={styles.container} 
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={15} // Adjust this value as needed
    >
      <ScrollView 
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between' }}
        keyboardShouldPersistTaps="handled"
      > 
      <View style={styles.wrapper}>
      <View>
          <Text style={styles.title}>What should we call you?</Text>
          <Text style={styles.subtitle}>We’ll use this to personalize recommendations for you</Text>
          <TextInput
            style={styles.input}
            placeholder="Username eg John"
            value={username}
            onChangeText={setUsername}
            returnKeyType="done"
            onSubmitEditing={Keyboard.dismiss}
          />
        </View>
        <View style={styles.bottom}>
          <TouchableOpacity
            style={[styles.button, username.trim().length > 0 ? styles.buttonActive : styles.buttonInactive]}
            onPress={handleContinue}
            disabled={username.trim().length === 0}
          >
            {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Continue</Text>}
          </TouchableOpacity>
        </View>
      </View>
      
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 20,
    paddingTop: 90,
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto'
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 5,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 14,
    color: '#6E6E6E',
    textAlign: 'left',
    marginBottom: 25,
  },
  input: {
    width: '100%',
    height: 50,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    fontSize: 16,
    marginBottom: 20,
  },
  bottom: {
    padding: 0,
    marginBottom: 15,
    backgroundColor: '#FFFFFF',
  },
  button: {
    width: '100%',
    height: 50,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  buttonActive: {
    backgroundColor: '#0066CC',
  },
  buttonInactive: {
    backgroundColor: '#E0E0E0',
  },
  buttonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});

export default UsernameInputScreen;
