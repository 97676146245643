import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Linking,
  Image,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';

const Footer = () => {
  const { width } = useWindowDimensions();

  const getSectionWidth = () => {
    if (width >= 1024) return '25%'; // 4 per row
    if (width >= 768) return '50%';  // 2 per row
    return '100%';                   // stacked on phones
  };

  return (
    <View style={styles.footer}>
      <View style={styles.logoContainer}>
        <Image
          source={require('../assets/traaple-logo.png')}
          style={styles.logo}
        />
      </View>

      <View style={[styles.footerContent, { flexWrap: 'wrap' }]}>
        {/* About Traaple Section */}
        <View style={[styles.section, { width: getSectionWidth() }]}>
          <Text style={styles.sectionTitle}>About Traaple</Text>
          <TouchableOpacity onPress={() => Linking.openURL('https://about.traaple.com/')}>
  <Text style={styles.linkText}>About Us</Text>
</TouchableOpacity>
<TouchableOpacity onPress={() => Linking.openURL('https://about.traaple.com/faq.html')}>
  <Text style={styles.linkText}>FAQs</Text>
</TouchableOpacity>
<TouchableOpacity onPress={() => Linking.openURL('https://about.traaple.com/careers.html')}>
  <Text style={styles.linkText}>Careers</Text>
</TouchableOpacity>
<TouchableOpacity onPress={() => Linking.openURL('https://about.traaple.com/contact.html')}>
  <Text style={styles.linkText}>Contact Us</Text>
</TouchableOpacity>

        </View>

        {/* Do Business With Us Section */}
        <View style={[styles.section, { width: getSectionWidth() }]}>
          <Text style={styles.sectionTitle}>Do Business With Us</Text>
          <TouchableOpacity onPress={() => Linking.openURL('https://about.traaple.com/providers.html')}>
  <Text style={styles.linkText}>Service Providers</Text>
</TouchableOpacity>

        </View>

        {/* Get The App Section */}
        <View style={[styles.section, { width: getSectionWidth() }]}>
          <Text style={styles.sectionTitle}>Get The App</Text>
          <View style={styles.appLinks}>
            <TouchableOpacity onPress={() => Linking.openURL('https://apps.apple.com')}>
              <Image
                source={require('../assets/apple-store-logo.png')}
                style={styles.appLogo}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('https://play.google.com')}>
              <Image
                source={require('../assets/google-play-logo.png')}
                style={styles.appLogo}
              />
            </TouchableOpacity>
          </View>
        </View>

        {/* Social Media Section */}
        <View style={[styles.section, { width: getSectionWidth() }]}>
          <Text style={styles.sectionTitle}>Catch Us On Social Media</Text>
          <View style={styles.socialIcons}>
            <TouchableOpacity style={styles.socialIcon} onPress={() => Linking.openURL('#')}>
              <Image source={require('../assets/x_logo.png')} style={styles.socialImage} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.socialIcon} onPress={() => Linking.openURL('#')}>
              <Image source={require('../assets/instagram.png')} style={styles.socialImage} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.socialIcon} onPress={() => Linking.openURL('#')}>
              <Image source={require('../assets/in_logo.png')} style={styles.socialImage} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.socialIcon} onPress={() => Linking.openURL('#')}>
              <Image source={require('../assets/fb_logo.png')} style={styles.socialImage} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'column',
    backgroundColor: '#E6EFF9',
    paddingVertical: 60,
    paddingHorizontal: 40,
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    width: '100%',
    marginBottom: 25,
  },
  logo: {
    width: 160,
    height: 30,
    marginRight: 5,
    resizeMode: 'contain',
  },
  footerContent: {
    flexDirection: 'row',
    width: '100%',
  },
  section: {
    paddingRight: 20,
    marginBottom: 30,
  },
  sectionTitle: {
    fontSize: 17,
    marginBottom: 16,
    color: '#6C7681',
  },
  linkText: {
    fontSize: 15,
    color: '#000',
    marginBottom: 12,
  },
  appLinks: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  appLogo: {
    width: 100,
    height: 30,
    resizeMode: 'contain',
    marginRight: 10,
  },
  socialIcons: {
    flexDirection: 'row',
    marginTop: 10,
  },
  socialIcon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  socialImage: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
});

export default Footer;
