import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, Image, Dimensions, ScrollView,KeyboardAvoidingView, Platform, Alert, ActivityIndicator  } from 'react-native';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { useFocusEffect } from '@react-navigation/native';
// import * as ImagePicker from 'expo-image-picker';
import authService from '../services/authService';
import fileService from '../services/fileService';

const interests = [
  { id: '1', name: 'Activities', image: require('../assets/interests/activities.png'), inactive_image: require('../assets/interests/activities-gray.png') },
  { id: '2', name: 'Attractions', image: require('../assets/interests/attractions.png',), inactive_image: require('../assets/interests/attractions-gray.png') },
  { id: '3', name: 'Tours', image: require('../assets/interests/tours.png'), inactive_image: require('../assets/interests/tours-gray.png') },
  { id: '4', name: 'Events', image: require('../assets/interests/events.png'), inactive_image: require('../assets/interests/events-gray.png') },
  { id: '5', name: 'Nightlife', image: require('../assets/interests/nightlife.png'), inactive_image: require('../assets/interests/nightlife-gray.png') },
  { id: '6', name: 'Restaurants', image: require('../assets/interests/restaurants.png'), inactive_image: require('../assets/interests/restaurants-gray.png') },
];

const UserProfileEditScreen = () => {
  const navigation = useNavigation();
  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);



  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isUploadingToServer, setIsUploadingToServer] = useState(false);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [selectedInterestsChanged, setSelectedInterestsChanged] = useState(false);


  useFocusEffect(
    useCallback(() => {
      checkLoggedIn();
    }, [])
  );

  useEffect(() => {
    // getUserProfile from authService

    const getUserProfile = async () => {
      try {
        const response = await authService.getUserProfile();
        if (response) {
          console.log('User profile:', response.user);
          setUsername(response.user.username);
          setEmail(response.user.email);
          console.log("COUNTRIES OF INTEREST");
          console.log(JSON.parse(response.user.countries_of_interest));
          setSelectedCountries(JSON.parse(response.user.countries_of_interest));
          console.log("INTERESTS");
          console.log(JSON.parse(response.user.interests));
          setSelectedInterests(JSON.parse(response.user.interests));

          if (response.user.avatar != null || response.user.avatar != '' || response.user.avatar != undefined) {
            setProfileImage({ uri: response.user.avatar });
          }
          
        } else {
          console.log('User profile not found');
        }
      } catch (error) {
        console.error('Error getting user profile:', error);
      }
    };
    getUserProfile();

  }, []);


  const checkLoggedIn = async () => {
    try {
      const response = await authService.checkLoggedIn();
      if (response) {
        console.log('User is logged in');
      } else {
        console.log('User is not logged in');
        navigation.navigate('LoginScreen');
      }
    } catch (error) {
      console.error('Error checking login status:', error);
    }
  };

  // const pickImage = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     mediaTypes: ImagePicker.MediaTypeOptions.Images,
  //     allowsEditing: true,
  //     aspect: [1, 1],
  //     quality: 1,
  //   });

  //   if (!result.canceled) {
  //     console.log(result)
  //     setProfileImage(result.assets[0]);

  //     try {
  //       const file = {
  //         uri: result.assets[0].uri,
  //         type: 'image/jpeg',
  //       };
  //       setIsUploadingToServer(true);
  //       const response = await fileService.uploadFile(file);
  //       console.log('File uploaded:', response);

  //       if (response.url != null || response.url != '' || response.url != undefined) {
  //         setUploadedImageUrl(response.url);
  //         setIsUploadingToServer(false);
  //       }
  //     }

  //     catch (error) {
  //       console.error('Error uploading file:', error);
  //       setIsUploadingToServer(false);
  //       Alert.alert('Error', 'Failed to upload file. Please try again later.');
  //     }
  //   }
  // };

  const toggleCountrySelection = (countryId) => {
    setSelectedCountries((prev) => {
      if (prev.includes(countryId)) {
        return prev.filter((id) => id !== countryId);
      } else {
        return [...prev, countryId];
      }
    });
  };

  const toggleInterestSelection = (interestId) => {

    setSelectedInterestsChanged(true);

    setSelectedInterests((prev) => {
      if (prev.includes(interestId)) {
        return prev.filter((id) => id !== interestId);
      } else {
        return [...prev, interestId];
      }
    });
  };

  const saveProfile = async () => {
    // Handle profile save action
    if (isUploadingToServer) {
      Alert.alert('Please wait', 'Update is in progress. Please wait for it to finish.');
    }

    if (uploadedImageUrl) {
      // Update user profile with uploadedImageUrl
      setIsUploadingToServer(true);
      const response = await authService.updateUserImage(uploadedImageUrl);

        if (response !== null) {
        setIsUploadingToServer(false);
      }
    }

    if (usernameChanged) {
      // Update user profile with username
      setIsUploadingToServer(true);
      const response = await authService.updateUser(username);

      if (response !== null) {
        setIsUploadingToServer(false);
      }
    }

    if (selectedInterestsChanged) {
      // Update user profile with selectedInterests
      setIsUploadingToServer(true);
      const response = await authService.updateUserInterests(JSON.stringify(selectedInterests));

      if(response !== null) {
        setIsUploadingToServer(false);
      }
    }

    setUsernameChanged(false);
    setSelectedInterestsChanged(false);
    // navigate back
    //navigation.goBack();
  };



  return (
    <KeyboardAvoidingView
    style={{ flex: 1 }}
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    keyboardVerticalOffset={Platform.OS === 'ios' ? 10 : 0} // Adjust the offset if necessary
  >
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={{ alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, height: 40, paddingHorizontal: 5, paddingTop: 12, width: '10%' }}>
            <Text style={{ color: '#ccc', fontSize: 12 }}>
              <Image source={require('../assets/chevron-left-grey-bg.png')} style={{ width: 30, height: 30 }} />
            </Text>
          </TouchableOpacity>
          <Text style={styles.title}>Personal Information</Text>
          {
            isUploadingToServer ? 
            (
            <ActivityIndicator size="small" color="#0066CC" />
            )
            : 
            (
              <TouchableOpacity onPress={saveProfile}>
                <Text style={styles.saveButton}>Save</Text>
              </TouchableOpacity>
              )
          }
        </View>
        <View style={styles.profileSection}>
          <TouchableOpacity onPress={() => {/*pickImage*/}}>
            <Image source={
              profileImage ? { uri: profileImage.uri } : require('../assets/auth/avatar1.png')
            } style={styles.profileImage} />
            <Image source={require('../assets/photoedit.png')} style={styles.editIcon} />
          </TouchableOpacity>

          <Text style={styles.inputLabel}>Username</Text>
          <TextInput
            style={styles.input}
            placeholder="Ana Williams"
            value={username}
            onChangeText={(text) => {setUsername(text); setUsernameChanged(true);}}
          />

          <Text style={styles.inputLabel}>Email</Text>
          <TextInput
            style={styles.input}
            placeholder="Email"
            value={email}
            onChangeText={setEmail}
          />
          <View style={styles.passwordSection}>
            <View style={{ width: '50%' }}>
              <Text style={styles.inputLabel}>Password</Text>
            </View>
            <View style={{ alignItems: 'flex-end', width: '50%' }}>
              <TouchableOpacity>
                <Text style={styles.changePasswordText}>Change password</Text>
              </TouchableOpacity>
            </View>
          </View>
          <TextInput
            style={styles.passwordInput}
            placeholder="..........."
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />
        </View>
        <View style={styles.countriesSection}>
          <View style={styles.countriesTitle}>
            <View style={{ width: '70%' }}>
              <Text style={styles.sectionTitle}>Countries you want to go to</Text>
            </View>
            <View style={{ alignItems: 'flex-end', width: '30%' }}>
              <TouchableOpacity>
                <Text style={styles.editButton}>Edit</Text>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.selectedCountriesContainer}>
            {selectedCountries.map(country => (
              <View key={country.id} style={styles.selectedCountry}>
                <Image source={{uri: 'https://bucket.traaple.com/flags/' + country.flag + '.png'}} style={styles.flag} />
                <Text>{country.name}</Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.interestsSection}>
          <Text style={styles.sectionTitle}>Interests</Text>
          <FlatList
            data={interests}
            keyExtractor={(item) => item.id}
            numColumns={3}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => toggleInterestSelection(item.id)}>
                <View style={[styles.interestItem, selectedInterests.includes(item.id) && styles.selectedInterestItem]}>
                {selectedInterests.includes(item.id) &&  <Image source={item.image} style={styles.interestImage} /> }

                {!selectedInterests.includes(item.id) &&  <Image source={item.inactive_image} style={styles.interestImage} /> }
                  <View style={styles.interestOverlay}>
                    <Text style={styles.interestName}>{item.name}</Text>

                    {selectedInterests.includes(item.id) && (
                      <View style={styles.checkmarkContainer}>
                        <Image source={require('../assets/selectedblue.png')} style={styles.checkmark} />
                      </View>
                    )}
                    {!selectedInterests.includes(item.id) && (
                      <View style={styles.checkmarkContainer}>
                        <Image source={require('../assets/emptyselect.png')} style={styles.checkmark} />
                      </View>
                    )}
                  </View>
                </View>
              </TouchableOpacity>
            )}
          />
        </View>
      </ScrollView>
    </View>
    </KeyboardAvoidingView>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#FFFFFF',
  },
  header: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    marginTop: 30,
  },
  backButton: {
    fontSize: 24,
    color: '#1A1A1A',
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1A1A1A',
  },
  saveButton: {
    fontSize: 16,
    color: '#0066CC',
  },
  profileSection: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: 20,
    marginBottom: 20
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
    marginBottom: 40,
  },
  editIcon: {
    width: 45,
    height: 45,
    position: 'absolute',
    bottom: 15,
    right: -15,
  },
  inputLabel: {
    alignSelf: 'flex-start',
    fontSize: 16,
    marginBottom: 10,
  },
  input: {
    width: '100%',
    height: 60,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  passwordSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  passwordInput: {
    height: 60,
    borderColor: '#E6E7E8',
    backgroundColor: '#E6E7E8',
    borderWidth: 1,
    borderRadius: 5,
    width: '100%',
    fontSize: 50,
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    paddingBottom: 30,
    paddingHorizontal: 10,
  },
  changePasswordText: {
    marginLeft: 10,
    color: '#0066CC',
  },
  countriesSection: {
    padding: 20,
    borderBottomColor: '#E0E0E0',
    borderBottomWidth: 1,
    backgroundColor: '#FFFFFF',
    marginBottom: 20
  },
  countriesTitle: {
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
  },
  editButton: {
    color: '#0066CC',
  },
  selectedCountriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  selectedCountry: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#F0F0F0',
    borderWidth: 1,
    borderRadius: 50,
    padding: 20,
    margin: 5,
  },
  flag: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  interestsSection: {
    backgroundColor: '#FFFFFF',
    padding: 15,
  },
  interestItem: {
    width: (width - 50) / 3,
    height: 125,
    margin: 3,
    borderRadius: 15,
    overflow: 'hidden',
  },
  selectedInterestItem: {
  },
  interestImage: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
  },
  interestOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  interestName: {
    fontSize: 15,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  checkmarkContainer: {
    position: 'absolute',
    top: 10,
    left: 10,
    borderRadius: 15,
    padding: 5,
  },
  checkmark: {
    color: '#0066CC',
    fontWeight: 'bold',
    width: 20,
    height: 20,
  },

});

export default UserProfileEditScreen;
