import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Image, ActivityIndicator, Alert } from 'react-native';
import authService from '../services/authService';
// import { socialSignIn } from '../utils/socialSignIn';
import { useNavigation } from '@react-navigation/native';

const SignupScreen = () => {
  const navigation = useNavigation();
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCriteria, setPasswordCriteria] = useState({
    minChar: false,
    numberSymbol: false,
    upperLowercase: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);  // Loader state

  const updatePassword = (password) => {
    setPassword(password);
    setPasswordCriteria({
      minChar: password.length >= 6,
      numberSymbol: /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password),
      upperLowercase: /[A-Z]/.test(password) && /[a-z]/.test(password),
    });
  };

  const handleSignup = async () => {
    setLoading(true);  // Start loading
    try {
      const response = await authService.signup(email, password);
      console.log('Signup success:', response);

      if(!response.verified) {
        navigation.navigate('VerifyEmailScreen', { email: email });
      } else {
        navigation.navigate('QuickGlanceScreen'); 
      }

      // navigation.navigate('UserProfileScreen');
    } catch (error) {
      setLoading(false);  // Stop loading
      console.error('Signup error:', error.response ? error.response.data : error);
      Alert.alert('Error', 'Failed to signup. Please try again later.');
    }
  };

  const checkPasswordCriteria = (criteria) => {
    return passwordCriteria[criteria] ? styles.criteriaValid : styles.criteriaInvalid;
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>


      <Text style={styles.mainTitle}>Create an account</Text>

      <Text style={styles.label}>Email</Text>
      <TextInput
        style={[styles.input, isEmailFocused && styles.inputFocused]}
        value={email}
        onChangeText={setEmail}
        placeholder='name@email.com'
        keyboardType="email-address"
        autoCapitalize="none"
        onFocus={() => setIsEmailFocused(true)}
        onBlur={() => setIsEmailFocused(false)}
      />

      <Text style={styles.label}>Create password</Text>
      <TextInput
        style={[styles.input, isPasswordFocused && styles.inputFocused]}
        value={password}
        onChangeText={updatePassword}
        placeholder='********'
        onFocus={() => setIsPasswordFocused(true)}
        onBlur={() => setIsPasswordFocused(false)}
        secureTextEntry
      />

      {
        passwordCriteria ? (
<View style={styles.passwordCriteria}>
  <View style={styles.criteriaContainer}>
    {passwordCriteria.minChar ? (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIcon} />
        <Text style={checkPasswordCriteria('minChar')}>At least 6 characters</Text>
      </>
    ) : (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIconInactive} />
        <Text style={checkPasswordCriteria('minChar')}>At least 6 characters</Text>
      </>
    )}
  </View>
  <View style={styles.criteriaContainer}>
    {passwordCriteria.numberSymbol ? (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIcon} />
        <Text style={checkPasswordCriteria('numberSymbol')}>At least 1 number and 1 symbol</Text>
      </>
    ) : (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIconInactive} />
        <Text style={checkPasswordCriteria('numberSymbol')}>At least 1 number and 1 symbol</Text>
      </>
    )}
  </View>
  <View style={styles.criteriaContainer}>
    {passwordCriteria.upperLowercase ? (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIcon} />
        <Text style={checkPasswordCriteria('upperLowercase')}>At least 1 uppercase letter and 1 lowercase letter</Text>
      </>
    ) : (
      <>
        <Image source={require('../assets/rounded-check.png')} style={styles.criteriaIconInactive} />
        <Text style={checkPasswordCriteria('upperLowercase')}>At least 1 uppercase letter and 1 lowercase letter</Text>
      </>
    )}
  </View>
</View>

        ) : (
          null
        )
      }

      <TouchableOpacity style={styles.button} onPress={handleSignup}>
      {loading ? <ActivityIndicator color="#fff" /> : <Text style={styles.buttonText}>Sign up</Text>}
      </TouchableOpacity>

      <View style={{ flexDirection: 'row', marginVertical: 20 }}>
        <View style={{ width: '44%', borderBottomWidth: 1, borderBottomColor: '#ccc' }}></View>
        <View style={{ width: '12%', alignItems: 'center' }}>
          <Text style={{ marginBottom: -5 }}>or</Text>
        </View>
        <View style={{ width: '44%', borderBottomWidth: 1, borderBottomColor: '#ccc' }}></View>
      </View>

      {/* Social Sign-In Buttons */}
      {/* Use your custom button styles here, replacing the default Button component */}
      {/* <TouchableOpacity style={styles.socialButton} onPress={() => socialSignIn('google')}>
      <Image source={require('../assets/auth/google.png')} style={styles.socialButtonImage} />
        <Text style={styles.socialButtonText}>Continue with Google</Text>
      </TouchableOpacity> */}

      {/* <TouchableOpacity style={styles.socialButton} onPress={() => socialSignIn('apple')}>
      <Image source={require('../assets/auth/apple.png')} style={styles.socialButtonImage} />
      <Text style={styles.socialButtonText}>Continue with Apple</Text>
      </TouchableOpacity> */}
{/* 
      <TouchableOpacity style={styles.socialButton} onPress={() => socialSignIn('facebook')}>
      <Image source={require('../assets/auth/facebook.png')} style={styles.socialButtonImage} />
      <Text style={styles.socialButtonText}>Continue with Facebook</Text>
      </TouchableOpacity> */}

      {/* Guest Option */}
      <TouchableOpacity style={styles.socialButton} onPress={() => navigation.navigate('QuickGlanceScreen')}>
        <Text style={styles.guestText}>Continue as guest</Text>
      </TouchableOpacity>

      {/* Already have an account */}
      <View style={{ alignItems: 'center' }}>
        <TouchableOpacity style={styles.accountAlready} onPress={() => navigation.navigate('LoginScreen')}>
          <Text style={styles.loginText}>Already have an account? </Text>
          <Text style={styles.loginTextDiff}>Log in</Text>
        </TouchableOpacity>
      </View>
      </View>

      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    paddingTop: 80,
    backgroundColor: '#fff'
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto'
  },
  mainTitle: {
    fontSize: 30,
    color: '#000',
    marginBottom: 30
  },
  input: {
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    fontSize: 16
  },
  button: {
    backgroundColor: '#000', // Use your app's primary color
    padding: 20,
    marginVertical: 10,
    borderRadius: 50,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  passwordCriteria: {
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  criteriaValid: {
    color: '#4CB367',
  },
  criteriaInvalid: {
    color: '#6C7681',
  },
  label: {
    alignSelf: 'flex-start',
  },  socialButton: {
    // Style for Google button
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: 18,
    marginVertical: 10,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#ccc',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  socialButtonImage: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  socialButtonText: {
    color: '#000'
  },
  accountAlready: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 30
  },
  loginText: {
    color: '#000'
  },
  loginTextDiff: {
    color: '#005FC5'
  },
  inputFocused: {
    borderColor: '#005FC5',
  },
  criteriaContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  criteriaIcon: {
    marginRight: 5,
    tintColor: '#4CB367',
    width: 18,
    height: 18
  },
  criteriaIconInactive: {
    marginRight: 5,
    tintColor: '#6C7681',
    width: 18,
    height: 18
  },
});

export default SignupScreen;
