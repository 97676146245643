import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Alert } from 'react-native';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigation } from '@react-navigation/native';
import FilterOption from '../components/atoms/FilterOption';
import experienceService from '../services/experienceService';
import AppHeader from '../components/organisms/AppHeader';

const BookExperienceScreen = ({ route }) => {
  const navigation = useNavigation();
  const params = route.params;
  const [experience, setExperience] = useState(null);
  const [guests, setGuests] = useState(1);
  const [date, setDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState('');
  const [timeOfDay, setTimeOfDay] = useState(null);

  useEffect(() => {
    const fetchExperienceDetails = async () => {
      try {
        const data = await experienceService.getExperienceDetails(params.experienceId);
        setExperience(data);
      } catch (error) {
        console.error("Error fetching experience details:", error.response ? error.response.data : error);
      }
    };

    fetchExperienceDetails();
  }, [params.experienceId]);

  useEffect(() => {
    // Set initial formatted date
    setFormattedDate(formatDate(date));
  }, [date]);


  const incrementGuests = () => {
    setGuests(guests + 1);
  };

  const decrementGuests = () => {
    if (guests > 1) {
      setGuests(guests - 1);
    }
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    setFormattedDate(formatDate(selectedDate));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Added +1 because months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

  const handleTimeOfDaySelection = (time) => {
    setTimeOfDay(time);
  };

  const validateSelections = () => {
    if (!timeOfDay || date < new Date().setHours(0, 0, 0, 0)) {
      return false;
    }
    return true;
  };

  const handleContinue = () => {
    if (!validateSelections()) {
      Alert.alert('Invalid selection', 'Please select a valid date and time of day to continue.');
      return;
    }

    navigation.navigate('ConfirmAndPayScreen', {
      experienceId: params.experienceId,
      guests,
      date: formattedDate,
      timeOfDay
    });
  };

  return (
    <>
      <AppHeader title="Book experience" onGoBack={() => navigation.goBack()} />
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Number of guests</Text>
          <View style={styles.guestsContainer}>
            <TouchableOpacity onPress={decrementGuests} style={styles.guestButton}>
              <Text style={styles.guestButtonText}>-</Text>
            </TouchableOpacity>
            <Text style={styles.guestsCount}>{`${guests} persons`}</Text>
            <TouchableOpacity onPress={incrementGuests} style={styles.guestButton}>
              <Text style={styles.guestButtonText}>+</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Date</Text>
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            className="date-picker"
          />
          <Text style={styles.sectionContent}>{formattedDate}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Time of day</Text>
          <View style={styles.timeOfDayContainer}>
            {['Morning (10AM - 12PM)', 'Afternoon (12PM - 4PM)', 'Evening (4PM - 8PM)'].map((time) => (
              <FilterOption
                key={time}
                label={time}
                value={time}
                onPress={() => handleTimeOfDaySelection(time)}
                isSelected={timeOfDay === time}
              />
            ))}
          </View>
        </View>
        <TouchableOpacity
          style={[styles.continueButton, { opacity: validateSelections() ? 1 : 0.5 }]}
          onPress={handleContinue}
        >
          <Text style={styles.continueButtonText}>Continue</Text>
        </TouchableOpacity>
      </ScrollView>
    </>
  );
};


const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: '#F8F8F8',
    padding: 20,
    paddingTop: 40,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  backButton: {
    marginRight: 10,
  },
  backButtonText: {
    fontSize: 24,
    color: '#000',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
  },
  section: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#6E6E6E',
    marginBottom: 10,
  },
  guestsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#E6E7E8',
    borderWidth: 1,
    borderRadius: 50,
    padding: 5,
    width: 180,
    gap: 10,
  },
  guestButton: {
    borderRadius: 20,
    padding: 10,
  },
  guestButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  guestsCount: {
    fontSize: 16,
    fontWeight: '600',
    color: '#000',
  },
  sectionContent: {
    fontSize: 16,
    color: '#000',
  },
  timeOfDayContainer: {
    gap: 10,
  },
  continueButton: {
    backgroundColor: '#0066CC',
    borderRadius: 25,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  continueButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});

export default BookExperienceScreen;
