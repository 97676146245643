import axios from 'axios';
import AsyncStorage from '../services/AsyncStorage';

const API_BASE_URL = 'https://traaple-api-su1z.onrender.com/api'; // INPUT_REQUIRED {API URL}


export const searchLocations = async (query) => {
    try {
        const token = await AsyncStorage.getItem('userToken');
        const response = await axios.get(`${API_BASE_URL}/places/search?query=${query}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
        console.log("Locations searched successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error searching locations:", error.response ? error.response.data : error);
        throw error;
    }
};

export default {
    searchLocations
};
