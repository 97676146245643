import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    Text,
    ImageBackground,
    StyleSheet,
    useWindowDimensions,
    Animated,
} from 'react-native';

const images = [
    require('../assets/header-1.jpg'),
    require('../assets/header-2.jpg'),
    require('../assets/header-3.jpg'),
];

const HeaderSection = () => {
    const { width } = useWindowDimensions();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(1);
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const isMobile = width < 768;
    const isTablet = width >= 768 && width < 1024;

    const dynamicStyles = {
        headerContainer: {
            height: isMobile ? 250 : isTablet ? 350 : 500,
        },
        tagline: {
            fontSize: isMobile ? 24 : isTablet ? 36 : 45,
            maxWidth: isMobile ? '90%' : isTablet ? '70%' : '50%',
            bottom: isMobile ? 30 : 60,
        },
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fadeAnim.setValue(0);

            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start(() => {
                setCurrentIndex(nextIndex);
                setNextIndex((nextIndex + 1) % images.length);
                fadeAnim.setValue(0);
            });
        }, 5000);

        return () => clearInterval(interval);
    }, [nextIndex]);

    return (
        <View style={[styles.headerContainer, dynamicStyles.headerContainer]}>
            {/* Base image (always visible) */}
            <ImageBackground
                source={images[currentIndex]}
                style={styles.headerBackground}
                imageStyle={styles.imageStyle}
            >
                <View style={styles.overlay}>
                    <Text style={[styles.tagline, dynamicStyles.tagline]}>
                        Discover and book things to do across{' '}
                        <Text style={styles.highlight}>Africa.</Text>
                    </Text>
                </View>

                {/* Fading image on top */}
                <Animated.Image
                    source={images[nextIndex]}
                    style={[
                        styles.fadingImage,
                        { opacity: fadeAnim },
                    ]}
                    resizeMode="cover"
                />
            </ImageBackground>
        </View>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        width: '100%',
        borderRadius: 10,
        overflow: 'hidden',
    },
    headerBackground: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        position: 'relative',
    },
    imageStyle: {
        resizeMode: 'cover',
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
        zIndex: 1,
    },
    tagline: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        position: 'absolute',
    },
    highlight: {
        color: '#00ADEF',
    },
    fadingImage: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 0,
    },
});

export default HeaderSection;
