import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

const Tags = ({ tags }) => {
  // Parse tags if they come as a string
  let parsedTags = [];

  try {
    parsedTags = JSON.parse(tags);
  } catch (error) {
    console.error('Error parsing tags:', error);
  }

  // Ensure parsedTags is an array
  if (!Array.isArray(parsedTags)) {
    parsedTags = [];
  }

  return (
    <View style={styles.tagsContainer}>
      {parsedTags.map((tag, index) => (
        <View key={index} style={styles.tagItem}>
          <Image source={require('../../assets/tflag.png')} style={styles.tagIcon} />
          <Text style={styles.tagText}>{tag}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
  },
  tagItem: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#E0E0E0', // Gray background
    padding: 5,
    borderRadius: 15,
    marginRight: 10,
    marginBottom: 10,
  },
  tagIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    tintColor: '#6E6E6E', // Gray color for the icon
  },
  tagText: {
    fontSize: 14,
    color: '#6E6E6E', // Gray color for the text
  },
});

export default Tags;
