import React, { useEffect, useState } from 'react';
import { View, Text, Animated, StyleSheet, ScrollView, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import experienceService from '../services/experienceService';
import bucketListService from '../services/bucketListService';
import { useNavigation } from '@react-navigation/native';
// import * as Sharing from 'expo-sharing';
import ProductPrice from '../components/atoms/ProductPrice';

const HEADER_MAX_HEIGHT = 300;
const HEADER_MIN_HEIGHT = 60;
const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;

const ExperienceDetailScreen = ({ route }) => {
    const navigation = useNavigation();
    const params = route.params;
    const [experience, setExperience] = useState(null);
    const [package_includes, setPackageIncludes] = useState([]);
    const [loading, setLoading] = useState(true);

    const scrollY = new Animated.Value(0);

    useEffect(() => {
        const fetchExperienceDetails = async () => {
            try {
                const data = await experienceService.getExperienceDetails(params.item.id);
                setExperience(data);
                console.log("Package details fetched successfully:");
                console.log(data.package_includes);
                setPackageIncludes(JSON.parse(data.package_includes) || []);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching experience details:", error.response ? error.response.data : error);
            }
        };

        fetchExperienceDetails();
    }, [params.item.id]);

    const handleAddToBucketList = async (id) => {
        try {
            await bucketListService.addExperienceToBucketList(id);
            alert('Experience added to bucket list successfully');
        } catch (error) {
            console.error("Error adding experience to bucket list:", error.response ? error.response.data : error);
            alert('Failed to add experience to bucket list');
        }
    };

    const iconMapping = {
        bottle: require('../assets/benefits/bottle.png'),
        // Add other icons as needed
    };

    if (loading) {
        return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="large" /></View>;
    }

    const headerHeight = scrollY.interpolate({
        inputRange: [0, HEADER_SCROLL_DISTANCE],
        outputRange: [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        extrapolate: 'clamp',
    });

    return (
        <View style={{ flex: 1, backgroundColor: '#fff' }}>

            <ScrollView
                style={{ flex: 1 }}
                contentContainerStyle={styles.scrollViewContent}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                    { useNativeDriver: false }
                )}
                scrollEventThrottle={16}
            >

                <Image
                    source={{ uri: experience.image }}
                    style={[styles.header]}
                />
                <View style={styles.contentContainer}>
                    <View style={{ backgroundColor: '#fff', borderTopLeftRadius: 20, borderTopRightRadius: 20, padding: 20 }}>
                        <View style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20, borderBottomWidth: 1, borderBottomColor: '#f6f6f6' }}>
                            <View style={{ width: '50%' }}>
                                <Text style={{ color: '#000C16', fontSize: 20, marginBottom: 10 }}>
                                    {experience.title}
                                </Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Image source={require('../assets/tflag.png')} style={{ width: 15, height: 15, marginRight: 5 }} />
                                    <Text style={{ color: '#6C7681', fontSize: 16 }}>
                                        {experience.location}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ alignItems: 'flex-end', borderRadius: 50, width: '50%', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
                                <View style={{ backgroundColor: '#000C16', borderRadius: 5, padding: 10 }}>
                                    <ProductPrice amount={experience.price} fontSize={12} fontWeight="normal" color="white" />
                                </View>
                                <TouchableOpacity style={styles.bookNowButton} activeOpacity={0.9} onPress={() => { navigation.navigate('BookExperienceScreen', { experienceId: params.item.id }) }}>
                                    <Text style={styles.bookNowButtonText}>Book now</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20, borderBottomWidth: 1, borderBottomColor: '#f6f6f6' }}>
                            <View style={{ width: '100%' }}>
                                <Text style={{ color: '#6C7681', fontSize: 18, marginBottom: 10 }}>
                                    Description
                                </Text>
                                <Text style={{ color: '#000C16', fontSize: 16 }}>
                                    {experience.description}
                                </Text>
                            </View>
                        </View>

                        <View style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20, borderBottomWidth: 1, borderBottomColor: '#f6f6f6' }}>
                            <View style={{ width: '100%' }}>
                                <Text style={{ color: '#6C7681', fontSize: 18, marginBottom: 10 }}>
                                    What’s included
                                </Text>

                                {package_includes.map((item, index) => (
                                    <View key={index} style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20 }}>
                                        <View style={{ borderRadius: 50, width: '15%' }}>
                                            <View style={{ alignItems: 'center', backgroundColor: '#f6f6f6', borderRadius: 5, height: 60, paddingTop: 20 }}>
                                                <Text style={{ color: '#000' }}>
                                                    <Image source={iconMapping[item.icon]} style={{ width: 20, height: 20 }} />
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ paddingTop: 20, paddingLeft: 20, width: '80%' }}>
                                            <Text style={{ color: '#000C16', fontSize: 16, marginBottom: 10 }}>
                                                {item.qty} {item.title}
                                            </Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </View>

                        <View style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20, borderBottomWidth: 1, borderBottomColor: '#f6f6f6' }}>
                            <View style={{ width: '100%' }}>
                                <Text style={{ color: '#6C7681', fontSize: 18, marginBottom: 10 }}>
                                    Time of Day
                                </Text>

                                <View style={{ width: '100%', flexDirection: 'row', paddingTop: 20, paddingBottom: 20 }}>
                                    <View style={{ borderRadius: 50, width: '15%' }}>
                                        <View style={{ alignItems: 'center', backgroundColor: '#f6f6f6', borderRadius: 5, height: 60, paddingTop: 20 }}>
                                            <Text style={{ color: '#000' }}>
                                                <Image source={require('../assets/time.png')} style={{ width: 20, height: 20 }} />
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ paddingLeft: 20, width: '80%' }}>
                                        <Text style={{ color: '#000C16', fontSize: 16, marginBottom: 10 }}>
                                            Afternoon
                                        </Text>

                                        <Text style={{ color: '#6C7681', fontSize: 16, marginBottom: 10 }}>
                                            1:00 PM - 4:00 PM
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>


                    </View>
                </View>
            </ScrollView>



            <View style={styles.topButtonsContainer}>
                <TouchableOpacity onPress={() => navigation.goBack()} style={styles.topButton}>
                    <Image source={require('../assets/chevron-left.png')} style={styles.topButtonIcon} />
                </TouchableOpacity>
                <TouchableOpacity style={styles.topButton} onPress={() => {
                    // Sharing.shareAsync("https://traaple.com", {})
                }}>
                    <Image source={require('../assets/share.png')} style={styles.topButtonIcon} />
                </TouchableOpacity>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        top: 100,
        left: 30,
        width: '95%',
        height: 300,
        backgroundColor: '#fff',
        overflow: 'hidden',
        borderRadius: 20,
    },
    scrollViewContent: {
        paddingTop: 10,
    },
    contentContainer: {
        marginTop: 100,
        backgroundColor: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    topButtonsContainer: {
        position: 'absolute',
        top: 40,
        left: 20,
        right: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topButton: {
        backgroundColor: '#fff',
        borderRadius: 50,
        padding: 10,
        elevation: 5,
    },
    topButtonIcon: {
        width: 20,
        height: 20,
    },
    bookNowContainer: {
        bottom: 20,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        paddingHorizontal: 20,
        alignSelf: 'center',
        width: '100%',
        paddingTop: 10,
        backgroundColor: '#fff',
    },
    bookNowButton: {
        alignItems: 'center',
        borderRadius: 50,
        backgroundColor: '#005FC5',
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: '25%',
    },
    bookNowButtonText: {
        color: '#fff',
        fontSize: 16,
    },

});

export default ExperienceDetailScreen;
