import React from 'react';
import { View } from 'react-native';
// import { WebView } from 'react-native-webview';

function PayScreen({ route }) {
  const { bookingId, email, totalPrice } = route.params;

  // Construct the URL with query parameters
  const paymentUrl = `https://paystack.traaple.com?txid=${bookingId}&email=${"test@traaple.com"}&amount=${totalPrice}`;

  console.log(paymentUrl);

  return (
    <View style={{ flex: 1 }}>
      {/* <WebView
        source={{ uri: paymentUrl }}
        style={{ flex: 1 }}
        onLoadStart={() => console.log('Loading started')}
        onLoad={() => console.log('Loading finished')}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn('WebView error: ', nativeEvent);
        }}
      /> */}
    </View>
  );
}

export default PayScreen;
