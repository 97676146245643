import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, Button, StyleSheet, Alert, Image } from 'react-native';
import bucketListService from '../services/bucketListService';

const CommunityHomeScreen = () => {
  const [bucketList, setBucketList] = useState([]);

  useEffect(() => {
    fetchBucketList();
  }, []);

  const fetchBucketList = async () => {
    try {
      const response = await bucketListService.getBucketList();
      setBucketList(response.data);
      console.log('Bucket list fetched successfully.');
    } catch (error) {
      console.error('Failed to fetch bucket list:', error);
      Alert.alert("Error", "Failed to fetch bucket list");
    }
  };

  const handleRemoveExperience = async (experienceId) => {
    try {
      await bucketListService.removeExperienceFromBucketList(experienceId);
      fetchBucketList(); // Refresh list after removal
      console.log(`Experience with ID ${experienceId} removed from bucket list.`);
    } catch (error) {
      console.error(`Failed to remove experience with ID ${experienceId} from bucket list:`, error);
      Alert.alert("Error", "Failed to remove experience from bucket list");
    }
  };

  return (
    <View style={styles.container}>

      {bucketList?.length === 0 && (
        <View style={styles.zeroContainer}>
        <Text style={styles.zeroTitle}>No community posts...yet 😉</Text>
        <Text style={styles.zeroSubtitle}>This is your community. All posts will be found here.</Text>
        <Image source={require('../assets/zerocommunity.png')} style={styles.zeroBucket} />
      </View>
      )}

{bucketList?.length > 0 && (<Text style={styles.title}>My Bucket List</Text>)}
      <FlatList
        data={bucketList}
        keyExtractor={(item) => item.experienceId.toString()}
        renderItem={({ item }) => (
          <View style={styles.listItem}>
            <Text>{item.title}</Text>
            <Button title="Remove" onPress={() => handleRemoveExperience(item.experienceId)} />
          </View>
        )}
      />
    </View> 
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  listItem: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#cccccc',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  zeroContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 40,
    marginTop: 200
  },
  zeroTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
  },
  zeroSubtitle: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
    marginBottom: 20,
  },
  zeroBucket: {
    width: 250,
    height: 250,
    marginBottom: 20,
  },
});

export default CommunityHomeScreen;