import axios from 'axios';

const API_BASE_URL = 'https://traaple-api-su1z.onrender.com/api'; // INPUT_REQUIRED {API URL}

const getExperienceDetails = async (experienceId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/experiences/${experienceId}`);
        console.log("Experience details fetched successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching experience details:", error.response ? error.response.data : error);
        throw error;
    }
};

export const searchExperiences = async (queryString) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/experiences/search?${queryString}`);
        console.log("Experiences searched successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error searching experiences:", error.response ? error.response.data : error);
        throw error;
    }
};


const bookExperience = async (bookingData) => {
    console.log("Booking data:", bookingData);
    try {
        const response = await axios.post(`${API_BASE_URL}/experiences/book`, bookingData);
        console.log("Experience booked successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error booking experience:", error.response ? error.response.data : error);
        throw error;
    }
};


const createPaymentIntent = async (amount) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/experiences/create-payment-intent`, { amount });
        console.log("Payment intent created successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error creating payment intent:", error.response ? error.response.data : error);
        throw error;
    }
};


export default {
    getExperienceDetails,
    bookExperience,
    createPaymentIntent
};