import axios from 'axios';
import AsyncStorage from '../services/AsyncStorage';

const API_URL = 'https://traaple-api-su1z.onrender.com/api/bucketlist'; // INPUT_REQUIRED {API URL}

const addExperienceToBucketList = async (experienceId, collectionId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    const response = await axios.post(API_URL + '/add', { experienceId, collectionId }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log("Experience added to bucket list successfully:", response.data);
    return response;
  } catch (error) {
    console.error("Error adding experience to bucket list:", error.response ? error.response.data : error);
    throw error;
  }
};

const getBucketList = async (collectionId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    // log full url
    console.log(API_URL + '/list?collectionId='+collectionId);
    const response = await axios.get(API_URL + '/list?collectionId='+collectionId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Bucket list fetched successfully:", response.data);
    return response;
  } catch (error) {
    console.error("Error fetching bucket list:", error.response ? error.response.data : error);
    throw error;
  }
};

const removeExperienceFromBucketList = async (experienceId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    const response = await axios.delete(`${API_URL}/${experienceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Experience removed from bucket list successfully:", response.data);
    return response;
  } catch (error) {
    console.error("Error removing experience from bucket list:", error.response ? error.response.data : error);
    throw error;
  }
};

export default {
  addExperienceToBucketList,
  getBucketList,
  removeExperienceFromBucketList,
};