import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Animated, Share } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { useNavigation } from '@react-navigation/native';
import { timeAgo } from '../../utils/timeAgo';
import defaultAvatars from '../../utils/defaultAvatars';
import authService from '../../services/authService';
import Tags from '../../components/atoms/Tags';


const PostCard = ({ post, onLike, onComment, onShare, onPress, onOptionsPress }) => {
  const navigation = useNavigation();
  const [isFollowing, setIsFollowing] = useState(post.user_is_following);

  const [userHasLiked, setUserHasLiked] = useState(post.user_has_liked);
  const [likesCount, setLikesCount] = useState(parseInt(post.likes_count, 10));
  const [isProcessingLike, setIsProcessingLike] = useState(false);
  const [currentUserID, setCurrentUserID] = useState(null);

  const scaleValue = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    setUserHasLiked(post.user_has_liked);
    setLikesCount(parseInt(post.likes_count, 10));

    authService.getUserProfile().then((response) => {
      setCurrentUserID(response.user.id);
    });
  }, [post]);

  const handleFollowToggle = async () => {
    try {
      const response = await authService.followOrUnfollowUser(post.user_id);
      setIsFollowing(response.isFollowing);
    } catch (error) {
      console.error('Error toggling follow status:', error);
    }
  };

  const  handleLike = async () => {
    if (isProcessingLike) return; // Prevent multiple rapid clicks

    setIsProcessingLike(true);

    const newLikeStatus = !userHasLiked;
    setUserHasLiked(newLikeStatus);
    setLikesCount(prevCount => newLikeStatus ? prevCount + 1 : prevCount - 1);

    Animated.sequence([
      Animated.timing(scaleValue, {
        toValue: 1.5,
        duration: 150,
        useNativeDriver: true,
      }),
      Animated.timing(scaleValue, {
        toValue: 1,
        duration: 150,
        useNativeDriver: true,
      })
    ]).start();

    try {
      await onLike(post.id, newLikeStatus);
    } catch (error) {
      console.log('Error liking post:', error);
      // Revert the state if the request fails
      setUserHasLiked(!newLikeStatus);
      setLikesCount(prevCount => newLikeStatus ? prevCount - 1 : prevCount + 1);
    }

    setIsProcessingLike(false);
  };


  const handleShare = async () => {
    console.log("START SHARING");
    try {
      const result = await Share.share({
        message: post.content,
        url: post.url, // Optional: If there's a URL associated with the post
        title: `${post.content} 
        
        Posted by: ${post.user} • ${timeAgo(post.time)}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // Shared with activity type of result.activityType
        } else {
          // Shared
        }
      } else if (result.action === Share.dismissedAction) {
        // Dismissed
      }
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  return (
    <View style={styles.postContainer}>
      <View style={styles.postHeader}>
        <TouchableOpacity onPress={() => navigation.navigate('ProfileScreen', { userId: post.user_id, fromComment: true })}>
          <Image
            source={defaultAvatars.find(avatar => avatar.id === post.avatar)?.src || { uri: post.avatar }}
            style={styles.avatar}
          />
        </TouchableOpacity>
        <View style={styles.postHeaderText}>
          <Text style={styles.userName}>{post.user}</Text>
          {currentUserID != post.user_id && <Text style={styles.postTime}> • {timeAgo(post.time)} {!isFollowing && "•"} {!isFollowing && <Text style={styles.followText} onPress={handleFollowToggle}>Follow</Text>}</Text>}
        </View>
        <TouchableOpacity onPress={() => onOptionsPress(post.id)}>
          <Text style={styles.moreOptions}>...</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={() => onPress(post.id)}>
        <Hyperlink linkDefault={true} linkStyle={styles.link}>
          <Text style={styles.postContent}>{post.content}</Text>
        </Hyperlink>
      </TouchableOpacity>
      <Text style={styles.postCategory}>{post.category}</Text>

      {/* Render Tags Component */}
      <Tags tags={post.tags} />

      <View style={styles.postFooter}>
        <TouchableOpacity style={styles.likeButton} onPress={handleLike}>
          <Animated.Image
            source={userHasLiked ? require('../../assets/community/liked.png') : require('../../assets/community/like.png')}
            style={[styles.likeIcon, { transform: [{ scale: scaleValue }] }]}
          />
          <Text style={styles.likeCount}> {likesCount}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.commentButton} onPress={() => onComment(post.id)}>
          <Image source={require('../../assets/community/comment.png')} style={styles.commentIcon} />
          <Text style={styles.commentCount}> {post.comments_count}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.shareButton} onPress={() => {handleShare()}}>
          <Image source={require('../../assets/community/share.png')} style={styles.shareIcon} />
        </TouchableOpacity>
      </View>



     
    </View>
  );
};

const styles = StyleSheet.create({
  postContainer: {
    backgroundColor: '#FFFFFF',
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    borderColor: '#E0E0E0',
    borderWidth: 1,
  },
  postHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  postHeaderText: {
    flex: 1,
    flexDirection: 'row',
    gap: 5,
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  postTime: {
    fontSize: 14,
    color: '#6E6E6E',
  },
  followText: {
    color: '#0066CC',
  },
  moreOptions: {
    fontSize: 20,
    color: '#6E6E6E',
  },
  postContent: {
    fontSize: 16,
    marginBottom: 10,
  },
  link: {
    color: '#2980b9',
  },
  postCategory: {
    fontSize: 14,
    color: '#6E6E6E',
    marginBottom: 10,
  },
  postFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  likeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  likeIcon: {
    width: 18,
    height: 18,
  },
  likeCount: {
    fontSize: 14,
    color: '#FF0000',
    marginLeft: 5,
  },
  commentButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentIcon: {
    width: 18,
    height: 18,
  },
  commentCount: {
    fontSize: 14,
    color: '#6E6E6E',
    marginLeft: 5,
  },
  shareButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareIcon: {
    width: 18,
    height: 18,
  },
});

export default PostCard;
