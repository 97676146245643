import axios from 'axios';
import AsyncStorage from '../services/AsyncStorage';
import socialAuthUtils from '../utils/socialAuthUtils';
import { Alert } from 'react-native';

// Ensure this is set to your existing API server base URL before running the app
const API_URL = process.env.API_URL || 'https://traaple-api-su1z.onrender.com/api/'; // INPUT_REQUIRED {API server base URL}

const login = async (email, password) => {
  try {
    const response = await axios.post(API_URL + 'login', { email, password });
    if (response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Login successful, token stored.');
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error);
    throw error;
  }
};

const signup = async (email, password) => {
  try {
    const response = await axios.post(API_URL + 'signup', { email, password });
    console.log(response.data);
    if (response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Signup successful, token stored.');
    }
    return response.data;
  } catch (error) {
    console.error('Signup error:', error.response ? error.response.data : error);
    throw error;
  }
};

const checkLoggedIn = async () => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error checking if user is logged in:', error);
    throw error;
  }
}

const updateUser = async (username) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    const response = await axios.post(
      API_URL + 'update-user', // Ensure the correct HTTP method and endpoint
      {
        newName: username,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User updated:', response.data);

    if (response.data.user && response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Update successful, token stored.');
    }

    // Alert.alert('Success', 'User information updated successfully!');

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to update user information. Please try again.');

    return error;
  }
};

// getuser profile
const getUserProfile = async () => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    const response = await axios.get(
      API_URL + 'profile', // Ensure the correct HTTP method and endpoint
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User profile:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error getting user profile:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to get user profile. Please try again.');

    return error;
  }
};


const getUserProfileById = async (userId) => {
  try {

    const response = await axios.get(
      API_URL + 'public/profile?userId='+userId, // Ensure the correct HTTP method and endpoint
      {
        headers: {
        },
      }
    );

    console.log('User profile public:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error getting user profile:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to get user profile. Please try again.');

    return error;
  }
};


const updateUserImage = async (image) => {
  console.log("IMAGE", image)
  // if image is a number then set image to string of number else if it has uri in it set it to image.uri
  if (typeof image === 'number') {
    image = image.toString();
  }
  else if (!image.uri) {
    image = image;
  }
  else {
    image = image.uri;
  }

  console.log("IMAGE", image)

  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    console.log("IMAGE", image)

    const response = await axios.post(
      API_URL + 'update-user', // Ensure the correct HTTP method and endpoint
      {
        newImage: image,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User updated:', response.data);

    if (response.data.user && response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Update successful, token stored.');
    }

    // Alert.alert('Success', 'User information updated successfully!');

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to update user information. Please try again.');

    return error;
  }
};


const updateUserCountriesOfInterest = async (countries) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    const response = await axios.post(
      API_URL + 'update-user', // Ensure the correct HTTP method and endpoint
      {
        newCountriesOfInterest: JSON.stringify(countries),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User updated:', response.data);

    if (response.data.user && response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Update successful, token stored.');
    }

    // Alert.alert('Success', 'User information updated successfully!');

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to update user information. Please try again.');

    return error;
  }
}

const updateUserInterests = async (interests) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    const response = await axios.post(
      API_URL + 'update-user', // Ensure the correct HTTP method and endpoint
      {
        newInterests: interests,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User updated:', response.data);

    if (response.data.user && response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Update successful, token stored.');
    }

    // Alert.alert('Success', 'User information updated successfully!');

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to update user information. Please try again.');

    return error;
  }
}


const updateUserLocation = async (locationData) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Token:', token);

    const response = await axios.post(
      API_URL + 'update-user', // Ensure the correct HTTP method and endpoint
      {
        newLocation: locationData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('User updated:', response.data);

    if (response.data.user && response.data.user.token) {
      await AsyncStorage.setItem('userToken', response.data.user.token);
      console.log('Update successful, token stored.');
    }

    // Alert.alert('Success', 'User information updated successfully!');

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    // Alert.alert('Error', 'Failed to update user information. Please try again.');

    return error;
  }
}



const logout = async () => {
  try {
    await AsyncStorage.removeItem('userToken');
    console.log('Logout successful, token removed.');
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

const generateToken = async (user) => {
  if (!process.env.JWT_SECRET) {
    console.error('JWT_SECRET is not set. Please set it in your environment variables.');
    throw new Error('JWT_SECRET is not set in environment variables.');
  }
  try {
    const token = ''/*await jwt.sign({ id: user.id }, process.env.JWT_SECRET, { expiresIn: 24 * 60 * 60 });*/ // Corrected to use 'react-native-jwt'
    console.log('Token generated successfully.');
    return token;
  } catch (error) {
    console.error('Error generating token:', error);
    throw error;
  }
};

// This function is a placeholder to simulate finding or creating a user without a database
const findOrCreateUser = async (email, name) => {
  // Simulate finding or creating a user
  try {
    // Simulated user object
    const user = { id: Date.now(), email, name }; // Simulating user ID generation with timestamp
    console.log('User found or created:', user);
    return user;
  } catch (error) {
    console.error('Error finding or creating user:', error);
    throw error;
  }
};

const loginWithGoogle = async (accessToken) => {
  try {
    const { email, name } = await socialAuthUtils.verifyGoogleToken(accessToken);
    const user = await findOrCreateUser(email, name);
    const token = await generateToken(user);
    console.log('Google login successful');
    return { token, user };
  } catch (error) {
    console.error('Google login error:', error);
    throw error;
  }
};

const loginWithApple = async (accessToken) => {
  try {
    const { email, name } = await socialAuthUtils.verifyAppleToken(accessToken);
    const user = await findOrCreateUser(email, name);
    const token = await generateToken(user);
    console.log('Apple login successful');
    return { token, user };
  } catch (error) {
    console.error('Apple login error:', error);
    throw error;
  }
};

const loginWithFacebook = async (accessToken) => {
  try {
    const { email, name } = await socialAuthUtils.verifyFacebookToken(accessToken);
    const user = await findOrCreateUser(email, name);
    const token = await generateToken(user);
    console.log('Facebook login successful');
    return { token, user };
  } catch (error) {
    console.error('Facebook login error:', error);
    throw error;
  }
};

const checkIfFollowing = async (followedId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }

    const response = await axios.get(API_URL + `community/follow/status/${followedId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error checking follow status:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const followOrUnfollowUser = async (followedId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }

    const response = await axios.post(API_URL + `community/follow/${followedId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error toggling follow status:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const getFollowCounts = async (userId) => {
  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }

    const response = await axios.get(API_URL + `community/follow/counts/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching follow counts:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default {
  login,
  signup,
  getUserProfile,
  getUserProfileById,
  checkLoggedIn,
  updateUser,
  updateUserImage,
  updateUserCountriesOfInterest,
  updateUserInterests,
  updateUserLocation,
  logout,
  loginWithGoogle,
  loginWithApple,
  loginWithFacebook,
  checkIfFollowing,
  followOrUnfollowUser,
  getFollowCounts,
};