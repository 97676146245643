import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { useNavigation, CommonActions } from '@react-navigation/native';
// async storage
import AsyncStorage from '../../services/AsyncStorage';

const CommunityWelcomeScreen = () => {
    const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Welcome to Traaple community</Text>
      
      <View style={styles.item}>
        <Image source={require('../../assets/connect.png')} style={styles.icon} />
        <View style={styles.textContainer}>
          <Text style={styles.itemTitle}>Connect</Text>
          <Text style={styles.itemText}>Discover new friends who share your interests and travel history.</Text>
        </View>
      </View>
      
      <View style={styles.item}>
        <Image source={require('../../assets/cshare.png')} style={styles.icon} />
        <View style={styles.textContainer}>
          <Text style={styles.itemTitle}>Share</Text>
          <Text style={styles.itemText}>Share your adventures, tips, and recommendations with fellow travelers.</Text>
        </View>
      </View>

      <View style={styles.item}>
        <Image source={require('../../assets/engage.png')} style={styles.icon} />
        <View style={styles.textContainer}>
          <Text style={styles.itemTitle}>Engage</Text>
          <Text style={styles.itemText}>Join conversations, plan meetups, and collaborate on unforgettable experiences.</Text>
        </View>
      </View>

      <View style={styles.item}>
        <Image source={require('../../assets/explore.png')} style={styles.icon} />
        <View style={styles.textContainer}>
          <Text style={styles.itemTitle}>Explore</Text>
          <Text style={styles.itemText}>Discover hidden gems, insider tips, and off-the-beaten-path destinations from fellow community members.</Text>
        </View>
      </View>

      <View style={styles.bottomContainer}>
        <TouchableOpacity style={styles.button} onPress={async () => {
            // store community_onboarded in async storage
            await AsyncStorage.setItem('community_onboarded', '1');
            navigation.dispatch(
              CommonActions.navigate({
                name: 'CommunityFeed', // Name of your BottomTabNavigator stack screen
                params: {
                  screen: 'Community', // Name of the specific tab you want to navigate to
                },
              })
            );
        }}>
          <Text style={styles.buttonText}>Got it!</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: 20,
    paddingTop: 80,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#1A1A1A',
    marginBottom: 30,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
    gap: 5,
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 10,
  },
  textContainer: {
    flex: 1,
  },
  itemTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 5,
  },
  itemText: {
    fontSize: 16,
    color: '#6E6E6E',
  },
  bottomContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  button: {
    backgroundColor: '#0066CC',
    borderRadius: 30,
    paddingVertical: 15,
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default CommunityWelcomeScreen;
