import React, { useRef, useEffect } from 'react';
import { View, StyleSheet, PanResponder, Animated, Dimensions, TouchableOpacity, KeyboardAvoidingView } from 'react-native';

const BottomSheet = ({ isVisible, onHide, children }) => {
  const bottomSheetHeight = Dimensions.get('window').height * 0.7;
  const animatedValue = useRef(new Animated.Value(isVisible ? 1 : 0)).current;
  const previousY = useRef(0);
  const bottomSheetRef = useRef(null);
 
  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isVisible ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [isVisible]);

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => false,
      onMoveShouldSetPanResponderCapture: (_, gestureState) =>
        Math.abs(gestureState.dy) > Math.abs(gestureState.dx) && gestureState.dy > 0,
      onPanResponderGrant: () => {
        previousY.current = 0;
      },
      onPanResponderMove: (_, gestureState) => {
        const { dy } = gestureState;
        const newTranslateY = previousY.current + dy;

        if (newTranslateY >= 0 && newTranslateY <= bottomSheetHeight) {
          bottomSheetRef.current.setNativeProps({
            style: {
              transform: [{ translateY: newTranslateY }],
            },
          });
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        const { vy } = gestureState;

        if (vy >= 0.5) {
          // Swipe velocity is high, hide the bottom sheet
          onHide();
        } else if (vy <= -0.5) {
          // Swipe velocity is high in the upward direction, bring the bottom sheet to full height
          bottomSheetRef.current.setNativeProps({
            style: {
              transform: [{ translateY: 0 }],
            },
          });
          previousY.current = 0;
        } else {
          // Slow swipe or no swipe, snap the bottom sheet to the nearest position (expanded or collapsed)
          const currentTranslateY = previousY.current;
          const halfwayPoint = bottomSheetHeight / 2;

          if (currentTranslateY < halfwayPoint) {
            // Snap to expanded position
            bottomSheetRef.current.setNativeProps({
              style: {
                transform: [{ translateY: 0 }],
              },
            });
            previousY.current = 0;
          } else {
            // Snap to collapsed position
            bottomSheetRef.current.setNativeProps({
              style: {
                transform: [{ translateY: bottomSheetHeight }],
              },
            });
            previousY.current = bottomSheetHeight;
          }
        }
      },
    })
  ).current;

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [bottomSheetHeight, 0],
    extrapolate: 'clamp',
  });

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  if (!isVisible) {
    return null;
  }

  return (
    <View style={styles.overlay}>

      <TouchableOpacity style={styles.overlay} activeOpacity={1} onPress={onHide} />
      <KeyboardAvoidingView behavior="padding">
        <Animated.View
          ref={bottomSheetRef}
          style={[
            styles.bottomSheetContainer,
            { transform: [{ translateY }] },
            { opacity },
            { backgroundColor: '#ffffff' }
          ]}
          {...panResponder.panHandlers}
        >
          {/* <View style={styles.dragBar} /> */}

          {children}
        </Animated.View>
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  dragBar: {
    backgroundColor: '#ccc',
    width: 40,
    height: 5,
    borderRadius: 15,
    alignSelf: 'center',
  },
  bottomSheetContainer: {
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    minHeight: 200,
  },
});

export default BottomSheet;