import React, { useEffect, useState, useRef } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, ScrollView, Image, ImageBackground, TextInput, RefreshControl, ActivityIndicator, Animated, Modal } from 'react-native';
import axios from 'axios';
import { useNavigation, CommonActions } from '@react-navigation/native';
import BottomSheet from '../partials/BottomSheet';
import { createCollection } from '../services/collectionService';
import bucketListService from '../services/bucketListService';
import Tooltip from '../components/Tooltip';
import AsyncStorage from '../services/AsyncStorage';
import { displayPrice } from '../utils/displayPrice';
import ProductPrice from '../components/atoms/ProductPrice';
import CollectionsManager from '../components/organisms/CollectionsManager';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import HeaderSection from '../partials/HeaderSection';
import WhyChooseTraaple from '../partials/WhyChooseTraaple';
import TravelWithEase from '../partials/TravelWithEase';
import SearchScreen from './SearchScreen';

import { useWindowDimensions } from 'react-native';



const QuickGlanceScreen = () => {
    const navigation = useNavigation();
    const [isSearchModalVisible, setSearchModalVisible] = useState(false);
    const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [experience_categories, setExperienceCategories] = useState([]);
    const [text, onChangeText] = React.useState('');
    const [favitem, setFavItem] = useState(null);

    const [loading, setLoading] = useState(false);  // Loader state

    const [collection_name, setCollectionName] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const [refreshing, setRefreshing] = useState(false);
    const [notificationsToolTipVisible, setNotificationsToolTipVisible] = useState(false);
    const [searchToolTipVisible, setSearchToolTipVisible] = useState(false);

    const openSearchModal = () => setSearchModalVisible(true);
    const closeSearchModal = () => setSearchModalVisible(false);

    const scrollY = useRef(new Animated.Value(0)).current;
    const buttonOpacity = scrollY.interpolate({
        inputRange: [0, 150],
        outputRange: [1, 0],
        extrapolate: 'clamp'
    });

    const finishTour = async () => {
        await AsyncStorage.setItem('tour_finished', '1');
    }

    // checkTourFinished = async () => {
    //     try {
    //     const tour_finished = await AsyncStorage.getItem('tour_finished');
    //     if (tour_finished !== '1') {
    //         setSearchToolTipVisible(true);
    //     }
    //     } catch (error) {
    //     console.log(error);
    //     }
    // }

    const onRefresh = () => {
        setRefreshing(true);
        fetchCategories();
    };

    useEffect(() => {
        // checkTourFinished();
        fetchCategories();

    }, []);

    const hideBottomSheet = () => {
        setBottomSheetVisible(false);
    };


    const handleFilter = async () => {
        const params = new URLSearchParams();

        if (selectedOption) {
            params.append('filter', selectedOption);
        }

        const queryString = params.toString();
        console.log(queryString);
        // Assuming you have a searchExperiences function

        setBottomSheetVisible(false);

        navigation.navigate('ExperienceListScreen', { query: queryString });
    };



    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://traaple-api-su1z.onrender.com/api/quick-glance');
            const categoriesArray = Object.keys(response.data.data).map((key) => {
                return {
                    categoryName: key,
                    experiences: response.data.data[key],
                };
            });
    
            // Find the index of the "Must Try" section
            const mustTryIndex = categoriesArray.findIndex(
                (category) => category.categoryName === 'Must Try'
            );
    
            if (mustTryIndex !== -1 && isDesktop) {
                // Insert TravelWithEase component only on desktop
                categoriesArray.splice(mustTryIndex, 0, {
                    categoryName: 'TravelWithEase',
                    experiences: [<TravelWithEase key="travel-with-ease" />],
                });
            }
            
    
            setExperienceCategories(response.data.data.categories);
            setCategories(categoriesArray);
            console.log('Categories fetched successfully');
        } catch (error) {
            console.error('Failed to fetch categories:', error);
            alert('Failed to load categories. Please try again later.');
        } finally {
            setRefreshing(false);
        }
    };
    


    const handleCreateCollection = async () => {
        if (collection_name.trim().length > 0) {
            try {
                setLoading(true);
                let response = await createCollection(collection_name);

                let addbucketlist = await bucketListService.addExperienceToBucketList(favitem.id, response.id);

                console.log("ADD TO BUCKET LIST RESPONSE");
                console.log(addbucketlist);

                setLoading(false);
                console.log('Collection created successfully!');
                console.log(response);


                navigation.navigate('BucketListScreen', { collectionId: response.id });

            } catch (error) {
                setLoading(false);
                console.error("Error updating user:", error);
            }
        }
    };

    const { width } = useWindowDimensions();
    const isDesktop = width >= 1024;

    const isTablet = width >= 768 && width < 1024;
const isMobile = width < 768;

const searchBarDynamic = {
    width: isDesktop ? '40%' : isTablet ? '70%' : '90%',
    top: isDesktop ? -45 : isTablet ? -30 : -20,
};


const getCategoryItemWidth = () => {
    if (width >= 1200) return '16%';  // 6 per row
    if (width >= 992) return '20%';   // 5 per row
    if (width >= 768) return '25%';   // 4 per row
    if (width >= 480) return '33%';   // 3 per row
    return '48%';                     // 2 per row (small phones)
};


    return (
        <View style={styles.container}>
            <Animated.ScrollView style={styles.scrollContainer} onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                { useNativeDriver: true }
            )} refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }>
                <View style={styles.textboxContainer}>

                    <View style={[styles.shadowBox, { flexDirection: 'row', alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, flex: 1, paddingHorizontal: 0 }]}>

                        <HeaderSection navigation={navigation} />
                        

                        <Tooltip
                            isVisible={searchToolTipVisible}
                            content={
                                <View style={{ width: '100%' }}>
                                    <View style={{ width: '100%', paddingVertical: 10, paddingHorizontal: 5 }}>
                                        <Text style={{ color: '#fff', fontWeight: 'bold' }}>Search</Text>
                                    </View>
                                    <View style={{ width: '100%', paddingVertical: 5, paddingHorizontal: 5 }}>
                                        <Text style={{ color: '#fff' }}>Whatever it is you are looking to do, just put it here and we will do the rest</Text>
                                    </View>
                                    <TouchableOpacity
                                        style={{ width: '100%', paddingVertical: 10, paddingHorizontal: 5 }}
                                        activeOpacity={0.9}
                                        onPress={() => { setSearchToolTipVisible(false); setNotificationsToolTipVisible(true); }}>
                                        <Text style={{ color: '#fff', textAlign: 'right' }}>Next</Text>
                                    </TouchableOpacity>
                                </View>
                            }
                            contentStyle={{
                                backgroundColor: '#005FC5'
                            }}
                            placement="bottom">

                        </Tooltip>
                    </View>

                </View>
                <TouchableOpacity
                            onPress={() => {openSearchModal()}}
                            style={[styles.shadowBox, styles.searchBar, searchBarDynamic]}
                        >
                            <View style={styles.searchContent}>
                                <Image source={require('../assets/search.png')} style={styles.searchIcon} />
                                <Text style={styles.searchPlaceholder}>What do you want to do?</Text>
                            </View>
                        </TouchableOpacity>

               
                

                {/* <TravelWithEase /> */}


                <View style={styles.subHeaderView}>
                    <Text style={styles.subHeaderText}>Discover by experiences</Text>
                </View>

                <View style={styles.categoryContainer}>
    {experience_categories.map((category, index) => (
        <TouchableOpacity
    key={index}
    style={[styles.singleCategory, { width: getCategoryItemWidth() }]}
    onPress={() => navigation.navigate('ExperienceListScreen')}
    activeOpacity={0.5}
>
    <ImageBackground
        source={{ uri: category.image }}
        style={styles.singleCategoryImage}
        imageStyle={{ borderRadius: 15 }}
    >
        <Text style={{ color: 'white', fontFamily: 'Mont-Regular' }}>
            {category.name}
        </Text>
    </ImageBackground>
</TouchableOpacity>

 
    ))}
</View>

                <WhyChooseTraaple />

                {categories.map((category, index) => (
                    <View key={index} style={styles.categorySection}>
                        {category.categoryName !== 'categories' && <View style={styles.subHeaderView}>
                            <Text style={styles.subHeaderText}>{category.categoryName}</Text>
                            <TouchableOpacity style={styles.seeAllButton} onPress={() => navigation.navigate('ExperienceListScreen')}>
                                <Text style={styles.seeAllText}>See more</Text>
                            </TouchableOpacity>
                        </View>}

                        {category.categoryName !== 'categories' && <FlatList
                            data={category.experiences}
                            keyExtractor={(item) => item.id}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            renderItem={({ item }) => {
                                if (React.isValidElement(item)) {
                                    return item; // Render the component directly
                                }
                                return (
                                    <TouchableOpacity
                                        style={styles.item}
                                        activeOpacity={0.9}
                                        onPress={() => navigation.navigate('ExperienceDetailScreen', { item })}
                                        key={item.id.toString()}
                                    >
                                        <ImageBackground
                                            source={{ uri: item.image }}
                                            style={styles.singleHandpickedImage}
                                            imageStyle={{ borderRadius: 15 }}
                                        >
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setFavItem(item);
                                                    setBottomSheetVisible("CreateCollection");
                                                }}
                                                style={styles.badge}
                                            >
                                                <Image source={require('../assets/fav.png')} style={{ width: 30, height: 30 }} />
                                            </TouchableOpacity>
                            
                                            <View style={styles.priceTag}>
                                                <ProductPrice
                                                    amount={item.price}
                                                    fontSize={12}
                                                    fontWeight="normal"
                                                    color="white"
                                                />
                                            </View>
                                        </ImageBackground>
                            
                                        <View style={{ marginBottom: 7 }}>
                                            <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{item.title}</Text>
                                        </View>
                            
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Image
                                                source={require('../assets/tflag.png')}
                                                style={{ width: 15, height: 15, marginRight: 5 }}
                                            />
                                            <Text style={{ fontSize: 14 }}>{item.location}</Text>
                                        </View>
                                    </TouchableOpacity>
                                );
                            }}
                            
                        />}
                    </View>
                ))}
            </Animated.ScrollView>
            <Animated.View style={[styles.quickGlanceButtonContainer, { opacity: buttonOpacity }]}>
                <TouchableOpacity
                    onPress={() => setBottomSheetVisible("QuickGlance")}
                    activeOpacity={0.9}
                    style={styles.quickGlanceButton}
                >
                    <Image source={require('../assets/glance.png')} style={{ width: 20, height: 20, marginRight: 5 }} />
                    <Text style={{ color: '#fff', marginTop: 2 }}>Quick Glance</Text>
                </TouchableOpacity>
            </Animated.View>

            {/* Other components and code */}
            {bottomSheetVisible === "QuickGlance" && (
                <BottomSheet isVisible={bottomSheetVisible} onHide={hideBottomSheet}>
                    <View style={styles.bottomSheetContent}>
                        <View style={styles.bottomSheetHeader}>
                            <Text style={styles.bottomSheetTitle}>Quick Glance</Text>
                        </View>

                        <View style={styles.radioContainer}>
                            {['Handpicked for you', 'Popular activities', 'Hottest deals', 'Must try', 'New in Ghana', 'Great Reviews'].map((option, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={styles.radioOption}
                                    onPress={() => setSelectedOption(option)}
                                >
                                    <Text style={styles.radioText}>{option}</Text>
                                    <View style={[styles.radioCircle, selectedOption === option && styles.radioCircleSelected]}>
                                        {selectedOption === option ? <Image source={require('../assets/radio.png')} style={styles.radioIcon} /> : <Image source={require('../assets/empty-radio.png')} style={styles.radioIcon} />}
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </View>

                        <TouchableOpacity
                            style={[styles.button, selectedOption ? styles.buttonActive : styles.buttonInactive]}
                            onPress={handleFilter}
                            disabled={!selectedOption}
                        >
                            {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Go</Text>}
                        </TouchableOpacity>
                    </View>
                </BottomSheet>
            )}



            {/* Other components and code */}
            {bottomSheetVisible === "CreateCollection" && (
                <BottomSheet isVisible={bottomSheetVisible} onHide={hideBottomSheet}>

                    <CollectionsManager
                        favitem={favitem}
                        onClose={() => setBottomSheetVisible(false)}  // Pass a callback to close the form
                    />


                    {/* <View style={styles.bottomSheetContent}>
                        <View style={styles.bottomSheetHeader}>
                            <Text style={styles.bottomSheetTitle}>Save to a collection</Text>
                            <TouchableOpacity style={styles.closeButton} onPress={hideBottomSheet}>
                                <Image source={require('../assets/close.png')} style={styles.closeIcon} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.noCollections}>
                            <Image source={require('../assets/starzero.png')} style={styles.starIcon} />
                            <Text style={styles.noCollectionsText}>You have no collections created.</Text>
                            <TouchableOpacity style={styles.createButton} onPress={() => {
                                setBottomSheetVisible("CollectionForm");
                            }}>
                                <Text style={styles.createButtonText}>+ Create collection</Text>
                            </TouchableOpacity>
                        </View>
                    </View> */}
                </BottomSheet>
            )}


            {bottomSheetVisible === "CollectionForm" && (
                <BottomSheet isVisible={bottomSheetVisible} onHide={hideBottomSheet}>
                    <View style={styles.bottomSheetContent}>
                        <View style={styles.bottomSheetHeader}>
                            <Text style={styles.bottomSheetTitle}>Create collection</Text>
                            <TouchableOpacity style={styles.closeButton} onPress={hideBottomSheet}>
                                <Image source={require('../assets/close.png')} style={styles.closeIcon} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.Collectionsform}>
                            <Text style={styles.inputLabel}>Collection name</Text>
                            <TextInput
                                style={styles.input}
                                placeholder="eg. Vacation trip"
                                value={collection_name}
                                onChangeText={setCollectionName}
                            />

                            <TouchableOpacity
                                style={[styles.button, collection_name.trim().length > 0 ? styles.buttonActive : styles.buttonInactive]}
                                onPress={handleCreateCollection}
                                disabled={collection_name.trim().length === 0}
                            >
                                {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Create</Text>}
                            </TouchableOpacity>
                        </View>
                    </View>
                </BottomSheet>
            )}


            <View style={styles.modalWrapper}>
            {/* Modal */}
            <Modal
    visible={isSearchModalVisible}
    transparent={true}
    animationType="fade"
    onRequestClose={closeSearchModal}
>
    <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
            {/* Modal Content */}
            <SearchScreen closeModal={closeSearchModal} navigation={navigation} />
            {/* <TouchableOpacity style={styles.closeButton} onPress={closeSearchModal}>
                <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity> */}
        </View>
    </View>
</Modal>


            </View>

            <Footer></Footer>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFFFFF',
    },
    shadowBox: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.05,
        shadowRadius: 3.84,
        elevation: 5,
    },
    scrollContainer: {
        paddingTop: 20,
        padding: 15
    },
    badge: {
        marginTop: 7,
        marginLeft: 5,
    },
    textboxContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 20,
        width: '100%'
    },
    subHeaderView: {
        marginTop: 20,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    seeAllButton: {
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 5
    },
    seeAllText: {
        color: '#005FC5',
        fontSize: 14
    },
    categorySection: {
        marginBottom: 20
    },
    radioOption: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        width: '100%',
        justifyContent: 'space-between',
    },
    radioText: {
        fontSize: 18,
        color: '#6C7681',
    },
    radioIcon: {
        width: 24,
        height: 24,
    },

    subHeaderText: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5
    },
    categoryContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 20,
    },
    
    singleCategory: {
        marginBottom: 12,
        paddingHorizontal: 5, // optional, keeps inner spacing nice
    },
    
    singleCategoryDivider: {
    },
    singleCategoryImage: {
        alignItems: 'center',
        width: '100%',
        height: 125,
        resizeMode: 'stretch',
        justifyContent: 'center',
    },
    categoryName: {
        fontSize: 20,
        marginLeft: 10,
        marginTop: 10
    },
    item: {
        marginRight: 20,
        width: 280
    },
    singleHandpickedImage: {
        marginBottom: 15,
        width: '100%',
        height: 180,
        resizeMode: 'stretch',
        paddingVertical: 5,
        paddingHorizontal: 6
    },
    text: {
        fontSize: 42,
    },
    priceTag: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        backgroundColor: '#000C16',
        paddingHorizontal: 12,
        borderRadius: 6,
        paddingVertical: 5
    },
    inputLabel: {
        color: '#6C7681',
        marginBottom: 6,
    },
    priceText: {
        color: 'white',
        fontSize: 12
    },
    bottomSheetContent: {
        padding: 10,
    },
    bottomSheetTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    closeIcon: {
        width: 30,
        height: 30,
    },
    noCollections: {
        alignItems: 'center',
        marginTop: 40,
    },
    starIcon: {
        width: 70,
        height: 70,
        marginBottom: 20,
    },
    noCollectionsText: {
        fontSize: 18,
        color: '#666',
        marginBottom: 20,
    },
    createButton: {
        borderWidth: 1,
        borderColor: '#005FC5',
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: 20,
    },
    createButtonText: {
        color: '#005FC5',
        fontSize: 16,
    },

    bottomSheetHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 50,
    },

    input: {
        width: '100%',
        height: 50,
        borderColor: '#E0E0E0',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
        fontSize: 16,
        marginBottom: 20,
    },
    button: {
        width: '100%',
        height: 50,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10,
    },
    buttonActive: {
        backgroundColor: '#0066CC',
    },
    buttonInactive: {
        backgroundColor: '#E0E0E0',
    },
    buttonText: {
        fontSize: 18,
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    quickGlanceButtonContainer: {
        position: 'absolute',
        bottom: 10,
        alignSelf: 'center',
    },
    quickGlanceButton: {
        alignSelf: 'center',
        backgroundColor: '#005FC5',
        borderRadius: 50,
        flexDirection: 'row',
        paddingVertical: 18,
        paddingHorizontal: 20,
    },
    searchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'center',
        backgroundColor: '#fff',
        borderRadius: 50,
        height: 50,
        width: '40%',
        paddingHorizontal: 10,
        top: -45
    },
    searchContent: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    searchIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    searchPlaceholder: {
        color: '#B0B4B7',
    },
    shadowBox: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 5,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    modalContent: {
        width: '60%', // Custom width
        height: 700, // Custom height
        backgroundColor: '#F9F9F9',
        borderRadius: 20,
        alignItems: 'center',
    },
    modalText: {
        fontSize: 18,
        textAlign: 'center',
    },
    closeButton: {
        backgroundColor: '#FF6347',
        padding: 10,
        borderRadius: 10,
    },

    modalWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        width: '50%',
    },  
});

export default QuickGlanceScreen;
