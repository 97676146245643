import AsyncStorage from './AsyncStorage';
import { Alert } from 'react-native';

// Replace with your actual ngrok URL
const API_URL = 'https://api.traaple.com/api/files/';

const uploadFile = async (file) => {
  console.log("FILE TO UPLOAD:", file);

  try {
    const token = await AsyncStorage.getItem('userToken');
    if (!token) {
      throw new Error('No token found');
    }

    // Validate file URI
    if (!file.uri || !file.uri.startsWith('file://')) {
      throw new Error('Invalid file URI');
    }

    const filename = file.uri.split('/').pop();
    const type = file.type || 'application/octet-stream';

    const formData = new FormData();
    formData.append('file', {
      uri: file.uri,
      type: type,
      name: filename,
    }); 

    // Log FormData parts to verify structure
    formData._parts.forEach(part => {
      console.log(`FormData part: ${part[0]} = ${JSON.stringify(part[1])}`);
    });

    const response = await fetch(`${API_URL}upload`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log('Fetch Response:', response);
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response data:', errorData);
      throw new Error('Failed to upload file');
    }

    const data = await response.json();
    console.log('File uploaded successfully:', data);
    return data;
  } catch (error) {
    console.error('Error message:', error.message);
    Alert.alert('Error', 'Failed to upload file. Please try again later.');
    throw error;
  }
};

export default {
  uploadFile,
};
