import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Image, ActivityIndicator, Alert, ScrollView } from 'react-native';
import authService from '../services/authService';
// import { socialSignIn } from '../utils/socialSignIn';
import { useNavigation } from '@react-navigation/native';

const LoginScreen = () => {
  const navigation = useNavigation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);  // Loader state

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await authService.login(email, password);
      console.log('Login success:', response);

      let user_profile = response.user;
      if (!user_profile?.verified) {
        console.log('User is not verified');
        navigation.navigate('VerifyEmailScreen', { email: user_profile?.email });
      }  else if (user_profile?.username === null || user_profile?.username === '') {
        console.log('User is not verified');
        navigation.navigate('UsernameInputScreen');
      } else if (user_profile?.avatar === null || user_profile?.avatar === '') {
        console.log('User is not verified');
        navigation.navigate('ProfileImageScreen', { username: user_profile?.username });
      } else if (user_profile?.countries_of_interest === null || user_profile?.countries_of_interest =='') {
        console.log('User is not verified');
        navigation.navigate('CountrySelectionScreen');
      } else if (user_profile?.interests === null || user_profile?.interests === '') {
        console.log('User is not verified');
        navigation.navigate('InterestsSelectionScreen');
      } else if (user_profile?.location === null || user_profile?.location === '') {
        console.log('User is not verified');
        navigation.navigate('LocationInputScreen');
      } else {
        navigation.navigate('QuickGlanceScreen');
      }
    } catch (error) {
      setLoading(false);
      console.error('Login error:', error.response ? error.response.data : error);
      Alert.alert('Error', 'Failed to login. Please try again later.');
    }
  };

  // const handleSocialSignIn = async (service) => {
  //   try {
  //     await socialSignIn(service);
  //     console.log(`${service} sign in success`);
  //   } catch (error) {
  //     console.error(`${service} sign in error:`, error);
  //   }
  // };


  return (
    <ScrollView style={styles.container}>
      <View style={styles.wrapper}>
      <Text style={styles.mainTitle}>Log into your account</Text>

<Text style={styles.label}>Email</Text>
<TextInput
  style={styles.input}
  value={email}
  onChangeText={setEmail}
  placeholder='name@email.com'
  keyboardType="email-address"
  autoCapitalize="none"
/>

<Text style={styles.label}>Password</Text>
<TextInput
  style={styles.input}
  value={password}
  onChangeText={setPassword}
  placeholder='********'
  secureTextEntry
  autoCapitalize="none"
/>

<TouchableOpacity style={styles.button} onPress={handleLogin}>
  {loading ? <ActivityIndicator size="small" color="white" /> : <Text style={styles.buttonText}>Login</Text>}
</TouchableOpacity>

<View style={{ flexDirection: 'row', marginVertical: 20 }}>
  <View style={{ width: '44%', borderBottomWidth: 1, borderBottomColor: '#ccc' }}></View>
  <View style={{ width: '12%', alignItems: 'center' }}>
    <Text style={{ marginBottom: -5 }}>or</Text>
  </View>
  <View style={{ width: '44%', borderBottomWidth: 1, borderBottomColor: '#ccc' }}></View>
</View>

{/* <TouchableOpacity style={styles.socialButton} onPress={() => handleSocialSignIn('google')}>
  <Image source={require('../assets/auth/google.png')} style={styles.socialButtonImage} />
  <Text style={styles.socialButtonText}>Continue with Google</Text>
</TouchableOpacity> */}


{/* <TouchableOpacity style={styles.socialButton} onPress={() => handleSocialSignIn('facebook')}>
  <Image source={require('../assets/auth/facebook.png')} style={styles.socialButtonImage} />
  <Text style={styles.socialButtonText}>Continue with Facebook</Text>
</TouchableOpacity> */}

{/* Guest Option */}
<TouchableOpacity style={styles.socialButton} onPress={() => navigation.navigate('QuickGlanceScreen')}>
  <Text style={styles.guestText}>Continue as guest</Text>
</TouchableOpacity>

{/* Already have an account */}
<View style={{ alignItems: 'center' }}>
  <TouchableOpacity style={styles.accountAlready} onPress={() => navigation.navigate('SignupScreen')}>
    <Text style={styles.loginText}>Don't have an account? </Text>
    <Text style={styles.loginTextDiff}>Sign up</Text>
  </TouchableOpacity>
</View>

      </View>

     

    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingTop: 80,
    backgroundColor: '#fff'
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto'
  },
  mainTitle: {
    fontSize: 25,
    color: '#000',
    marginBottom: 30
  },
  input: {
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    fontSize: 16
  },
  button: {
    backgroundColor: '#000', // Use your app's primary color
    padding: 20,
    marginVertical: 10,
    borderRadius: 50,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  label: {
    alignSelf: 'flex-start',
  },
  // Add styles for your social buttons
  socialButton: {
    // Style for Google button
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: 18,
    marginVertical: 10,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#ccc',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  socialButtonImage: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  socialButtonText: {
    color: '#000'
  },
  accountAlready: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 30
  },
  loginText: {
    color: '#000'
  },
  loginTextDiff: {
    color: '#005FC5'
  }
});

export default LoginScreen;
