// FilterOption.js
import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';

const FilterOption = ({ label, value, onPress, isSelected }) => (
  <TouchableOpacity
    style={[styles.optionButton, isSelected && styles.selectedOption]}
    onPress={onPress}
  >
    <Text style={[styles.filterValue, isSelected && styles.selectedValue]}>{label}</Text>
    {isSelected && (
      <Image source={require('../../assets/filter-check.png')} style={styles.closeIcon} />
    )}
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  optionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#E6E7E8',
    borderRadius: 50,
    padding: 12,
  },
  selectedOption: {
    borderColor: '#000C16',
    backgroundColor: '#F3F3F3'
  },
  filterValue: {
    color: '#6C7681',
    fontSize: 16,
  },
  selectedValue: {
    color: '#000C16',
  },
  closeIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
});

export default FilterOption;
