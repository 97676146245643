import React, { useEffect, useRef, useCallback } from 'react';
import { View, Image, StyleSheet, Animated, Easing } from 'react-native';
import authService from '../services/authService';

const SplashScreen = ({ navigation }) => {
  const logoPosition = useRef(new Animated.Value(100)).current; // Start off-screen to the right

  useEffect(() => {
    // Animate the logo to the center
    Animated.timing(logoPosition, {
      toValue: 0,
      duration: 2000, // Duration of the animation
      easing: Easing.out(Easing.exp),
      useNativeDriver: true,
    }).start(() => {
      // Navigate to the next screen after the animation
      setTimeout(async () => {

        try {
            const response = await authService.checkLoggedIn();
            if (response) {
              console.log('User is logged in');
              let user_profile = await getUserProfile();

              if (!user_profile?.verified) {
                console.log('User is not verified');
                navigation.navigate('VerifyEmailScreen', { email: user_profile?.email });
              }  else if (user_profile?.username === null || user_profile?.username === '') {
                console.log('User is not verified');
                navigation.navigate('UsernameInputScreen');
              } else if (user_profile?.avatar === null || user_profile?.avatar === '') {
                console.log('User is not verified');
                navigation.navigate('ProfileImageScreen', { username: user_profile?.username });
              } else if (user_profile?.countries_of_interest === null || user_profile?.countries_of_interest =='') {
                console.log('User is not verified');
                navigation.navigate('CountrySelectionScreen');
              } else if (user_profile?.interests === null || user_profile?.interests === '') {
                console.log('User is not verified');
                navigation.navigate('InterestsSelectionScreen');
              } else if (user_profile?.location === null || user_profile?.location === '') {
                console.log('User is not verified');
                navigation.navigate('LocationInputScreen');
              } else {
                navigation.navigate('QuickGlanceScreen');
              }
            } else {
              console.log('User is not logged in');
              navigation.navigate('WelcomeScreen');
            }
          } catch (error) {
            console.error('Error checking login status:', error);
          }
      }, 1000); // Wait for a second before navigating
    });
  }, [logoPosition, navigation]);


  const getUserProfile = async () => {
    return new Promise(async (resolve, reject) => {
    try {
      const response = await authService.getUserProfile();
      if (response) {
        console.log('User profile:', response.user);
        resolve(response.user);
      } else {
        console.log('User profile not found');
        reject('User profile not found');
      }
    } catch (error) {
      console.error('Error getting user profile:', error);
      reject(error);
    }
  });
  };

  return (
    <View style={styles.container}>
      <Animated.View style={{ transform: [{ translateX: logoPosition }] }}>
        <Image source={require('../assets/wordmark.png')} style={styles.logo} />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#0A2A45', // Background color of the splash screen
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 200, // Adjust the size of the logo
    height: 200, // Adjust the size of the logo
    resizeMode: 'contain',
  },
});

export default SplashScreen;
