import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, RefreshControl, FlatList, Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getCommunityPosts, likePost, deletePost } from '../../services/communityService';
import CommunityWelcomeScreen from './Welcome';
import { timeAgo } from '../../utils/timeAgo';
import PostCard from '../../components/organisms/postCard';
import BottomSheet from '../../partials/BottomSheet';
import authService from '../../services/authService';


const defaultAvatars = [
  { id: '1', src: require('../../assets/auth/avatar1.png') },
  { id: '2', src: require('../../assets/auth/avatar2.png') },
  { id: '3', src: require('../../assets/auth/avatar3.png') },
  { id: '4', src: require('../../assets/auth/avatar4.png') },
];

const CommunityFeed = () => {
  const [posts, setPosts] = useState([]);
  const navigation = useNavigation();
  const [refreshing, setRefreshing] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [currentActionPostID, setCurrentActionPostID] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [activateDelete, setActivateDelete] = useState(false);


  const onRefresh = () => {
    setRefreshing(true);
    fetchPosts();
  };

  useEffect(() => {
    fetchPosts();

    authService.getUserProfile().then((response) => {
      console.log("Result of getUserProfile", response);
      setUserProfile(response.user);
    });
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await getCommunityPosts();
      setRefreshing(false);
      setPosts(response);
    } catch (error) {
      console.error('Error fetching community posts:', error);
    }
  };

  const handleLike = async (postId) => {
    console.log(postId);
    try {
      const result = await likePost(postId);
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleComment = (postId) => {
    console.log(`Comment on post: ${postId}`);
    navigation.navigate('PostDetailScreen', { postId });
  };

  const handleShare = (postId) => {
    console.log(`Share post: ${postId}`);
  };

  const handlePress = (postId) => {
    navigation.navigate('PostDetailScreen', { postId });
  };

  const checkAllowDeletion = (postId, userId) => {
    setActivateDelete(false);

    authService.getUserProfileById(userId).then((response) => {
      if (response.user.username.toLowerCase() === userProfile.username.toLowerCase()) {
        setActivateDelete(true);
      }
    });
  };



  const renderPost = ({ item }) => (
    <PostCard
      post={item}
      onLike={handleLike}
      onComment={handleComment}
      onShare={handleShare}
      onPress={handlePress}
      onOptionsPress={() => {
        console.log("Test")
        setBottomSheetVisible(true);
        checkAllowDeletion(item.id, item.user_id);
      }}
    />
  );

  return (
    <View style={styles.container}>

      <View style={styles.header}>

        <View style={[{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, height: 50, flex: 1, paddingHorizontal: 0 }]}>

          {showSearch ? <TouchableOpacity onPress={() => navigation.navigate('SearchScreen')} style={[styles.shadowBox, { flexDirection: 'row', alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, height: 50, flex: 1, paddingHorizontal: 10 }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', borderWidth: 0 }}>
              <Image source={require('../../assets/community/Search.png')} style={{ width: 20, height: 20, marginRight: 10 }} />
              <Text
                style={{ height: '100%', width: '100%', color: '#B0B4B7' }}
              >Search experiences</Text>
            </View>
          </TouchableOpacity> : <TouchableOpacity style={[{ flexDirection: 'row', alignItems: 'center', borderRadius: 50, height: 50, flex: 1, paddingHorizontal: 10 }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', borderWidth: 0 }}>
              <Text
                style={{ height: '100%', width: '100%', color: '#000C16', fontSize: 25, fontWeight: 'bold' }}
              >Community</Text>
            </View>
          </TouchableOpacity>}

          {!showSearch && <TouchableOpacity onPress={() => setShowSearch(!showSearch)} style={[styles.shadowBox, { width: 50, height: 50, alignItems: 'center', justifyContent: 'center', marginLeft: 10, backgroundColor: '#fff', borderRadius: 50 }]}>
            <Image source={require('../../assets/community/Search.png')} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>}

          {!showSearch && <View style={[styles.shadowBox, { width: 50, height: 50, alignItems: 'center', justifyContent: 'center', marginLeft: 10, backgroundColor: '#fff', borderRadius: 50 }]}>
            <Image source={require('../../assets/notification.png')} style={{ width: 20, height: 20 }} />
          </View>}


          {showSearch && <TouchableOpacity onPress={() => setShowSearch(!showSearch)} style={[{ width: 50, height: 50, alignItems: 'center', justifyContent: 'center', marginLeft: 10, backgroundColor: '#fff', borderRadius: 50 }]}>
            <Text>Cancel</Text>
          </TouchableOpacity>}
        </View>



      </View>
      <FlatList
        data={posts}
        renderItem={renderPost}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={styles.postsList}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      />
      <TouchableOpacity style={styles.fab} onPress={() => navigation.navigate('NewPostScreen')}>
        <Text style={styles.fabText}>+</Text>
      </TouchableOpacity>




      <BottomSheet
  isVisible={bottomSheetVisible}
  onBackdropPress={() => setBottomSheetVisible(false)}
  onHide={() => setBottomSheetVisible(false)}
>
<View style={styles.bottomSheetContainer}>
        <TouchableOpacity 
          onPress={() => { 
            // Add your share functionality here
            setBottomSheetVisible(false);
          }}
          style={styles.blockButton}
        >
          <Text style={styles.blockButtonText}>Share</Text>
        </TouchableOpacity>
        <TouchableOpacity 
          onPress={() => { 
            // Add your report post functionality here
            setBottomSheetVisible(false);
          }}
          style={styles.blockButton}
        >
          <Text style={styles.blockButtonText}>Report Post</Text>
        </TouchableOpacity>
        <TouchableOpacity 
          onPress={() => { 
            // Add your block user functionality here
            setBottomSheetVisible(false);
          }}
          style={styles.blockButton}
        >
          <Text style={styles.blockButtonText}>Block User</Text>
        </TouchableOpacity>
        {
          activateDelete && (
            <TouchableOpacity
              onPress={() => {
                Alert.alert(
                  "Delete Post",
                  "Are you sure you want to delete this post?",
                  [
                    {
                      text: "Cancel",
                      onPress: () => setBottomSheetVisible(false),
                      style: "cancel"
                    },
                    {
                      text: "Delete",
                      onPress: async () => {
                        if (currentActionPostID) {
                          try {
                            await deletePost(currentActionPostID);
                            setBottomSheetVisible(false);
                            fetchPosts();  // Refresh the posts after deletion
                          } catch (error) {
                            console.error('Error deleting post:', error);
                            setBottomSheetVisible(false);
                          }
                        }
                      },
                      style: "destructive"
                    }
                  ],
                  { cancelable: true }
                );
              }}
              style={styles.blockButton}
            >
              <Text style={styles.blockButtonText}>Delete</Text>
            </TouchableOpacity>
          )
        }
      </View>
</BottomSheet>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F3F3F3',
    paddingTop: 125,
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    paddingTop: 60,
  },
  searchInput: {
    flex: 1,
    height: 40,
    borderColor: '#E0E0E0',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    marginRight: 10,
  },
  cancelText: {
    color: '#0066CC',
  },
  postsList: {
    padding: 10,
  },
  postContainer: {
    backgroundColor: '#FFFFFF',
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    borderColor: '#E0E0E0',
    borderWidth: 1,
  },
  postHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  postHeaderText: {
    flex: 1,
    flexDirection: 'row',
    gap: 5,
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  postTime: {
    fontSize: 14,
    color: '#6E6E6E',
  },
  followText: {
    color: '#0066CC',
  },
  moreOptions: {
    fontSize: 20,
    color: '#6E6E6E',
  },
  postContent: {
    fontSize: 16,
    marginBottom: 10,
  },
  postCategory: {
    fontSize: 14,
    color: '#6E6E6E',
    marginBottom: 10,
  },
  postFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  likeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  likeCount: {
    fontSize: 14,
    color: '#FF0000',
  },
  commentButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentCount: {
    fontSize: 14,
    color: '#6E6E6E',
  },
  shareButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareIcon: {
    fontSize: 14,
    color: '#0066CC',
  },
  navBar: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 60,
    borderTopColor: '#E0E0E0',
    borderTopWidth: 1,
  },
  navText: {
    fontSize: 16,
    color: '#1A1A1A',
  },
  fab: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    backgroundColor: '#0066CC',
    borderRadius: 30,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fabText: {
    color: '#FFFFFF',
    fontSize: 24,
  },
  shadowBox: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    elevation: 5,
  },
  shareButton: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 10,
  },
  shareButtonText: {
    fontSize: 16,
    color: '#007aff',
  },
  reportButton: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 10,
  },
  reportButtonText: {
    fontSize: 16,
  },
  blockButton: {
    padding: 12,
    borderRadius: 8,
  },
  blockButtonText: {
    fontSize: 16,
  },
});

export default CommunityFeed;
