import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import SplashScreen from './screens/SplashScreen';
// All other screens are commented out for now
import LoginScreen from './screens/LoginScreen';
import SignupScreen from './screens/SignupScreen';
import ExperienceDetailScreen from './screens/ExperienceDetailScreen';
import QuickGlanceScreen from './screens/QuickGlanceScreen';
import BottomTabNavigator from './BottomTabNavigator';
import WelcomeScreen from './screens/WelcomeScreen';
import CollectionsScreen from './screens/CollectionsScreen';
// import FilterScreen from '../screens/FilterScreen';
import BucketListScreen from './screens/BucketListScreen';
import VerifyEmailScreen from './screens/VerifyEmailScreen';
import UsernameInputScreen from './screens/UsernameInputScreen';
import ProfileImageScreen from './screens/ProfileImageScreen';
import CountrySelectionScreen from './screens/CountrySelectionScreen';
import InterestsSelectionScreen from './screens/InterestsSelectionScreen';
import LoadingScreen from './screens/LoadingScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import UserProfileEditScreen from './screens/UserProfileEditScreen';
import ExperienceListScreen from './screens/ExperienceListScreen';
import CommunityWelcomeScreen from './screens/community/Welcome';
import CommunityHomeScreen from './screens/CommunityHomeScreen';
import CommunityFeed from './screens/community/Feed';
// import PostDetailScreen from '../screens/community/Post';
import SearchScreen from './screens/SearchScreen';
// import NewPostScreen from '../screens/community/New';
// import ShareFeedbackScreen from '../screens/ShareFeedbackScreen';
// import EmptyExperienceScreen from '../screens/EmptyExperienceScreen';
// import ProfileScreen from '../screens/ProfileScreen';
import LocationInputScreen from './screens/LocationInputScreen';
import BookExperienceScreen from './screens/BookExperienceScreen';
import ConfirmAndPayScreen from './screens/ConfirmAndPayScreen';
import PayScreen from './screens/PayScreen';
import PaymentSuccessfulScreen from './screens/PaymentSuccessfulScreen';
import Header from './partials/Header';
import { useLinkTo } from '@react-navigation/native';

const Stack = createStackNavigator();

const linking = {
  prefixes: ['http://localhost:3000', 'https://traaple.com'],
  config: {
    screens: {
      QuickGlanceScreen: 'quick-glance',
      LoginScreen: 'login',
      SignupScreen: 'signup',
      WelcomeScreen: 'welcome',
      VerifyEmailScreen: 'verify-email',
      UsernameInputScreen: 'username',
      ProfileImageScreen: 'profile-image',
      CountrySelectionScreen: 'select-country',
      InterestsSelectionScreen: 'select-interests',
      SearchScreen: 'search',
      LocationInputScreen: 'location-input',
      LoadingScreen: 'loading',
      MainApp: {
        path: 'app',
        screens: {
          // Add tab-specific paths here if needed
        }
      },
      ExperienceDetailScreen: 'experience/:id',
      CollectionsScreen: 'collections',
      BucketListScreen: 'bucket-list',
      UserProfileScreen: 'user-profile',
      UserProfileEditScreen: 'edit-profile',
      ExperienceListScreen: 'experience-list',
      BookExperienceScreen: 'book-experience',
      ConfirmAndPayScreen: 'confirm-and-pay',
      PayScreen: 'pay',
      PaymentSuccessfulScreen: 'payment-success',
      CommunityWelcomeScreen: 'community/welcome',
      CommunityHomeScreen: 'community/home',
      CommunityFeed: 'community/feed',
    },
  },
};


const AppNavigator = () => (
  <NavigationContainer linking={linking}>
    <Stack.Navigator screenOptions={({ navigation }) => ({
      header: () => <Header navigation={navigation} />, // Pass navigation prop to Header component
    })}>
      {/* <Stack.Screen name="SplashScreen" component={SplashScreen} options={{ headerShown: false }} /> */}
      <Stack.Screen name="QuickGlanceScreen" component={QuickGlanceScreen} options={{ title: 'Traaple - Discover Africa' }} />
      <Stack.Screen name="LoginScreen" component={LoginScreen} options={{ headerShown: true, gestureEnabled: false }} />
      <Stack.Screen name="SignupScreen" component={SignupScreen} options={{ headerShown: true }} />
      <Stack.Screen name="WelcomeScreen" component={WelcomeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="VerifyEmailScreen" component={VerifyEmailScreen} options={{ headerShown: true }} />
      <Stack.Screen name="UsernameInputScreen" component={UsernameInputScreen} options={{ headerShown: true }} />
      <Stack.Screen name="ProfileImageScreen" component={ProfileImageScreen} options={{ headerShown: true }} />
      <Stack.Screen name="CountrySelectionScreen" component={CountrySelectionScreen} options={{ headerShown: true }} />
      <Stack.Screen name="InterestsSelectionScreen" component={InterestsSelectionScreen} options={{ headerShown: true }} />
      <Stack.Screen name="SearchScreen" component={SearchScreen} options={{ headerShown: true }} />
      <Stack.Screen name="LocationInputScreen" component={LocationInputScreen} options={{ headerShown: true }} />
      <Stack.Screen name="LoadingScreen" component={LoadingScreen} options={{ headerShown: true }} />
      <Stack.Screen name="MainApp" component={BottomTabNavigator} options={{ headerShown: false }} />
      
      <Stack.Screen name="ExperienceDetailScreen" component={ExperienceDetailScreen} options={{ title: 'Experience Details', headerShown: true }} />
      <Stack.Screen name="CollectionsScreen" component={CollectionsScreen} options={{ title: 'Collections' }} />
      {/* <Stack.Screen name="FilterScreen" component={FilterScreen} options={{ title: 'Filter', headerShown: false }} /> */}
      <Stack.Screen name="BucketListScreen" component={BucketListScreen} options={{ title: 'Bucket List' }} />
      <Stack.Screen name="UserProfileScreen" component={UserProfileScreen} options={{ title: 'User Profile', headerShown: true }} />
      <Stack.Screen name="UserProfileEditScreen" component={UserProfileEditScreen} options={{ title: 'Edit Profile', headerShown: true }} />
      <Stack.Screen name="ExperienceListScreen" component={ExperienceListScreen} options={{ title: 'Experience List', headerShown: true }} />
      <Stack.Screen name="BookExperienceScreen" component={BookExperienceScreen} options={{ title: 'Book Experience', headerShown: false }} />
      <Stack.Screen name="ConfirmAndPayScreen" component={ConfirmAndPayScreen} options={{ title: 'Confirm and Pay', headerShown: false }} />
      <Stack.Screen name="PayScreen" component={PayScreen} options={{ title: 'Pay', headerShown: false }} />
      <Stack.Screen name="PaymentSuccessfulScreen" component={PaymentSuccessfulScreen} options={{ title: 'Payment Successful', headerShown: false }} />
      <Stack.Screen name="CommunityWelcomeScreen" component={CommunityWelcomeScreen} options={{ headerShown: true }} />
      <Stack.Screen name="CommunityHomeScreen" component={CommunityHomeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="CommunityFeed" component={CommunityFeed} options={{ headerShown: true }} />

    </Stack.Navigator>

    
  </NavigationContainer>
);

export default AppNavigator;
