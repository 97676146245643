import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet, Alert, Image, RefreshControl, TouchableOpacity, ImageBackground } from 'react-native';
import bucketListService from '../services/bucketListService';
import {deleteCollection} from '../services/collectionService';
import { useNavigation } from '@react-navigation/native';
import BottomSheet from '../partials/BottomSheet';

const BucketListScreen = ({ route }) => {
  const navigation = useNavigation();
  const params = route.params;
  const [bucketList, setBucketList] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [currentActionItemId, setCurrentActionItemId] = useState(null);

  const onRefresh = () => {
    setRefreshing(true);
    fetchBucketList(params.collectionId);
  };

  useEffect(() => {
    fetchBucketList(params.collectionId);
  }, []);

  const fetchBucketList = async (collectionId) => {
    try {
      const response = await bucketListService.getBucketList(collectionId);
      setBucketList(response.data);
      console.log('Bucket list fetched successfully.');
      setRefreshing(false);
    } catch (error) {
      console.error('Failed to fetch bucket list:', error);
      Alert.alert("Error", "Failed to fetch bucket list");
      setRefreshing(false);
    }
  };

  const handleRemoveExperience = async (experienceId) => {
    try {
      await bucketListService.removeExperienceFromBucketList(experienceId);
      fetchBucketList(params.collectionId); // Refresh list after removal
      console.log(`Experience with ID ${experienceId} removed from bucket list.`);
    } catch (error) {
      console.error(`Failed to remove experience with ID ${experienceId} from bucket list:`, error);
      Alert.alert("Error", "Failed to remove experience from bucket list");
    }
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.item}
      activeOpacity={0.9}
      onPress={() => navigation.navigate('ExperienceDetailScreen', { item })}
    >
      <ImageBackground source={{ uri: item.image }} style={styles.singleHandpickedImage} imageStyle={{ borderRadius: 10, width: '100%' }}>
        <View style={styles.badge}>
          <Image source={require('../assets/fav.png')} style={{ width: 30, height: 30 }} />
        </View>
        <View style={{ backgroundColor: '#000', borderRadius: 5, padding: 8, position: 'absolute', bottom: 10, left: 10 }}>
          <Text style={{ color: '#fff', fontSize: 15 }}>
            $ {item.price}
          </Text>
        </View>
      </ImageBackground>
      <View style={{ marginBottom: 10 }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{item.title}</Text>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        <Image source={require('../assets/tflag.png')} style={{ width: 15, height: 15, marginRight: 5 }} />
        <Text style={{ fontSize: 14 }}>{item.location}</Text>
      </View>
      <TouchableOpacity
        style={styles.moreOptionsButton}
        onPress={() => {
          setCurrentActionItemId(item.id);
          setBottomSheetVisible(true);
        }}
      >
        <Text style={styles.moreOptions}>...</Text>
      </TouchableOpacity>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={{ alignItems: 'center', backgroundColor: '#fff', borderRadius: 50, height: 40, paddingHorizontal: 5, paddingTop: 12, width: '10%' }}>
          <Text style={{ color: '#ccc', fontSize: 12 }}>
            <Image source={require('../assets/chevron-left-grey-bg.png')} style={{ width: 30, height: 30 }} />
          </Text>
        </TouchableOpacity>
        <Text style={styles.title}>{params.collectionName}</Text>
        <TouchableOpacity onPress={() => setBottomSheetVisible(true)}>
        <Text style={styles.moreOptions}>...</Text>
        </TouchableOpacity>
      </View>
      {bucketList?.length === 0 ? (
        <View style={styles.zeroContainer}>
          <Text style={styles.zeroTitle}>No saved items...yet 😉</Text>
          <Text style={styles.zeroSubtitle}>Add some experiences to your bucket list!</Text>
          <Image source={require('../assets/zerobucket.png')} style={styles.zeroBucket} />
        </View>
      ) : (
        <FlatList
          data={bucketList}
          keyExtractor={(item) => item.id.toString()}
          renderItem={renderItem}
          contentContainerStyle={styles.scrollContainer}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        />
      )}

      <BottomSheet
        isVisible={bottomSheetVisible}
        onBackdropPress={() => setBottomSheetVisible(false)}
      >
        <View style={styles.bottomSheetContainer}>
          <TouchableOpacity
            onPress={() => {
              Alert.alert(
                "Delete",
                "Are you sure you want to remove this bucket list?",
                [
                  { text: "Cancel", style: "cancel" },
                  {
                    text: "Remove",
                    onPress: async () => {
                      try {
                        await deleteCollection(params.collectionId);
                        setBottomSheetVisible(false);
                        navigation.goBack();
                      } catch (error) {
                        console.error('Error removing collection:', error);
                        setBottomSheetVisible(false);
                      }
                    },
                    style: "destructive"
                  }
                ]
              );
            }}
            style={styles.blockButton}
          >
            <Text style={styles.blockButtonText}>Delete list</Text>
          </TouchableOpacity>

        </View>
      </BottomSheet>





    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1, // Ensure the container takes up the full height
    backgroundColor: '#FFFFFF',
  },
  scrollContainer: {
    padding: 20,
  },
  item: {
    width: '100%',
    marginBottom: 20,
    position: 'relative',
  },
  singleHandpickedImage: {
    marginBottom: 20,
    width: '100%',
    height: 200,
    resizeMode: 'stretch',
    paddingVertical: 5,
    borderRadius: 10,
  },
  title: {
    fontSize: 24,
  },
  badge: {
    marginTop: 7,
    marginLeft: 7,
  },
  shadowBox: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 3.84,
    elevation: 5,
  },
  header: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    paddingTop: 50, // Adjust for status bar height if needed
  },
  backButton: {
    fontSize: 24,
    color: '#1A1A1A',
  },
  saveButton: {
    fontSize: 16,
    color: '#0066CC',
  },
  moreOptionsButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  listItem: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#cccccc',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  zeroContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 40,
  },
  zeroTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
  },
  zeroSubtitle: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'center',
    marginBottom: 20,
  },
  zeroBucket: {
    width: 250,
    height: 250,
    marginBottom: 20,
  },
  bottomSheetContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  blockButton: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 10,
  },
  blockButtonText: {
    fontSize: 16,
  },
  moreOptions: {
    fontSize: 20,
    color: '#6E6E6E',
  },
});

export default BucketListScreen;
