import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, FlatList, KeyboardAvoidingView, Platform, ActivityIndicator, Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import ImagePicker from '../services/ImagePicker';
import authService from '../services/authService';
import fileService from '../services/fileService';

const defaultAvatars = [
  { id: '1', src: require('../assets/auth/avatar1.png') },
  { id: '2', src: require('../assets/auth/avatar2.png') },
  { id: '3', src: require('../assets/auth/avatar3.png') },
  { id: '4', src: require('../assets/auth/avatar4.png') },
];

const ProfileImageScreen = ({ route }) => {
  const { username } = route.params;
  const navigation = useNavigation();
  const [selectedImage, setSelectedImage] = useState(defaultAvatars[0].src);
  const [loading, setLoading] = useState(false);  // Loader state


  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Permission required', 'Traaple needs access to your photos library to enable picking of images for your profile photo.');
        }

        const { status: cameraStatus } = await ImagePicker.requestCameraPermissionsAsync();
        if (cameraStatus !== 'granted') {
          Alert.alert('Permission required', 'Traaple needs access to your camera to enable taking of photos for your profile photo.');
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });


    console.log(result.assets[0].uri);

    if (!result.canceled) {
      setSelectedImage({ uri: result.assets[0].uri });
      console.log(result.assets[0].uri)
      // Upload image to server
      const response = await fileService.uploadFile(result.assets[0]);
      console.log('Uploaded file URL:', response.url)
      setSelectedImage({ uri: response.url });
    }
  };

  const takePhoto = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    console.log("THE RESULT");
    console.log(result);

    if (!result.canceled) {
      setSelectedImage({ uri: result.assets[0].uri });
      console.log(result.assets[0].uri)
      // Upload image to server
      const response = await fileService.uploadFile(result.assets[0]);
      console.log('Uploaded file URL:', response.url)
      setSelectedImage({ uri: response.url });
    }
  };

  const handleContinue = async () => {
    console.log("THE SELECTED IMAGE");
    console.log(selectedImage);
    try {
      setLoading(true);
      let response = await authService.updateUserImage(selectedImage);

      if (response.success) {
        console.log("User updated successfully:", response.user);
        navigation.navigate('CountrySelectionScreen');
        setLoading(false);
      } else {
        console.error("Error updating user:", response.error);
        setLoading(false);
      }

    } catch (error) {
      setLoading(false);
      console.error("Error updating user:", error);
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >

      <View style={styles.wrapper}>
        <Text style={styles.title}>Select or upload profile image</Text>
        <Text style={styles.subtitle}>
          Add a profile image or select one of the existing images. Everyone will be able to see it
        </Text>
        {/* if selectedimage is a number, it's a default avatar, use id to get the src */}
        {
          <Image source={defaultAvatars.find(avatar => avatar.id === selectedImage)?.src ? defaultAvatars.find(avatar => avatar.id === selectedImage)?.src : selectedImage} style={styles.profileImage} />
        }


        <Text style={styles.name}>{username}</Text>
        <View style={styles.thumbnailsContainer}>
          <FlatList
            data={defaultAvatars}
            horizontal
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => {
                console.log(item.id)
                setSelectedImage(item.id)
              }}>
                <Image source={item.src} style={[styles.thumbnail, selectedImage === item.src && styles.selectedThumbnail]} />
              </TouchableOpacity>
            )}
            keyExtractor={item => item.id}
            contentContainerStyle={styles.thumbnailContainer}
          />
        </View>
        <View style={styles.dividerContainer}>
          <View style={styles.divider} />
          <Text style={styles.orText}>or</Text>
          <View style={styles.divider} />
        </View>
        <TouchableOpacity style={styles.optionButton} onPress={pickImage}>
          <Image source={require('../assets/auth/gallery.png')} style={styles.actionIcon} />
          <Text style={styles.optionText}>Select image from gallery</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.optionButton} onPress={takePhoto}>
          <Image source={require('../assets/auth/camera.png')} style={styles.actionIcon} />
          <Text style={styles.optionText}>Take photo</Text>
        </TouchableOpacity>
        <View style={styles.navigationContainer}>
          <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
            <Image source={require('../assets/back.png')} style={styles.backIcon} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.continueButton} onPress={handleContinue}>
            {loading ? <ActivityIndicator size="small" color="#FFFFFF" /> : <Text style={styles.continueText}>Continue</Text>}
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 20,
    paddingTop: 90,
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto'
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 16,
    color: '#6E6E6E',
    textAlign: 'left',
    marginBottom: 50,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
    marginBottom: 10,
    alignSelf: 'center',
  },
  name: {
    fontSize: 20,
    color: '#1A1A1A',
    marginBottom: 20,
    textAlign: 'center',
  },
  thumbnailsContainer: {
    marginBottom: 20,
    marginTop: 25,
    alignItems: 'center',
  },
  thumbnail: {
    width: 60,
    height: 60,
    borderRadius: 10,
    marginHorizontal: 5,
    borderColor: '#E0E0E0',
    borderWidth: 2,
  },
  selectedThumbnail: {
    borderColor: '#0066CC',
    borderWidth: 2,
  },
  thumbnailContainer: {
    marginBottom: 20,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: '#E0E0E0',
  },
  orText: {
    marginHorizontal: 10,
    color: '#6E6E6E',
  },
  optionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25,
  },
  actionIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  optionText: {
    fontSize: 17,
    color: '#6C7681',
  },
  navigationContainer: {
    flexDirection: 'row',
    top: 10,
    bottom: 10,
    justifyContent: 'space-between',
  },
  backButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 50,
    borderColor: '#005FC5',
    backgroundColor: '#E6EFF9',
    borderWidth: 1,
    marginRight: 10,
    maxWidth: 70,
  },
  backIcon: {
    width: 20,
    height: 20,
  },
  backText: {
    fontSize: 16,
    color: '#1A1A1A',
  },
  continueButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 25,
    backgroundColor: '#0066CC',
  },
  continueText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default ProfileImageScreen;
