import axios from 'axios';
import AsyncStorage from '../services/AsyncStorage';

const API_URL = 'https://traaple-api-su1z.onrender.com/api/community'; // INPUT_REQUIRED {API base URL}

const getHeaders = async () => {
  const token = await AsyncStorage.getItem('userToken');
  if (!token) {
    console.error('No token found');
    throw new Error('Authentication token is not available. Please login again.');
  }
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export const createPost = async (content, tags) => {
  try {
    const headers = await getHeaders();
    const response = await axios.post(`${API_URL}/post/create`, { content, tags }, { headers });
    console.log('Post created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error.response ? error.response.data : error);
    throw error;
  }
};

export const getCommunityPosts = async (userId = null, liked = false) => {
  try {
    const headers = await getHeaders();
    let url = `${API_URL}/community/list`;

    if (userId) {
      url += `?user=${userId}`;
      if (liked) {
        url += `&liked=true`;
      }
    } else if (liked) {
      url += `?liked=true`;
    }

    const response = await axios.get(url, { headers });
    console.log('Community posts fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching community posts:', error.response ? error.response.data : error);
    throw error;
  }
};


export const getUserComments = async () => {
  try {
    const headers = await getHeaders();
    let url = `${API_URL}/user/comments`;
    console.log(url);
    const response = await axios.get(url, { headers });
    console.log('User comments fetched successfully:', response.data);
    return response.data;
  }  catch (error) {
    console.error('Error fetching user comments:', error.response ? error.response.data : error);
    throw error;
  }
};


export const tagExperience = async (postId, tags) => {
  try {
    const headers = await getHeaders();
    const response = await axios.post(`${API_URL}/post/tagExperience`, { postId, tags }, { headers });
    console.log('Experience tagged successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error tagging experience:', error.response ? error.response.data : error);
    throw error;
  }
};

export const reportPost = async (postId, reason) => {
  try {
    const headers = await getHeaders();
    const response = await axios.post(`${API_URL}/post/report`, { postId, reason }, { headers });
    console.log('Post reported successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error reporting post:', error.response ? error.response.data : error);
    throw error;
  }
};


export const getPostById = async (postId) => {
    try {
      const headers = await getHeaders();
      const response = await axios.get(`${API_URL}/post/${postId}`, { headers });
      return response.data;
    } catch (error) {
      console.error('Error fetching post by id:', error.response ? error.response.data : error);
      throw error;
    }
  };


  export const likePost = async (postId) => {
    try {
      const headers = await getHeaders();
      const response = await axios.post(`${API_URL}/post/like/`+postId, { postId }, { headers });
      console.log('Post liked/unliked successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error liking/unliking post:', error.response ? error.response.data : error);
      throw error;
    }
  };
  
  export const commentOnPost = async (postId, content) => {
    try {
      const headers = await getHeaders();
      const response = await axios.post(`${API_URL}/post/comment/`+postId, { postId, content }, { headers });
      console.log('Comment created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error commenting on post:', error.response ? error.response.data : error);
      throw error;
    }
  };
  


  export const deletePost = async (postId) => {
    try {
      const headers = await getHeaders();
      const response = await axios.delete(`${API_URL}/post/${postId}`, { headers });
      console.log('Post deleted successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error deleting post:', error.response ? error.response.data : error);
      throw error;
    }
  };

  

  export const deleteComment = async (commentId) => {
    try {
      const headers = await getHeaders();
      const response = await axios.delete(`${API_URL}/comment/${commentId}`, { headers });
      console.log('Comment deleted successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error deleting comment:', error.response ? error.response.data : error);
      throw error;
    }
  };
  