import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList, Image, KeyboardAvoidingView, Platform, ActivityIndicator, Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import authService from '../services/authService';

const countries = [
  { "id": "1", "name": "Angola", "flag": "ao" },
  { "id": "2", "name": "Burkina Faso", "flag": "bf" },
  { "id": "3", "name": "Burundi", "flag": "bi" },
  { "id": "4", "name": "Benin", "flag": "bj" },
  { "id": "5", "name": "Botswana", "flag": "bw" },
  { "id": "6", "name": "Congo, the Democratic Republic of the", "flag": "cd" },
  { "id": "7", "name": "Central African Republic", "flag": "cf" },
  { "id": "8", "name": "Congo", "flag": "cg" },
  { "id": "9", "name": "Côte d'Ivoire", "flag": "ci" },
  { "id": "10", "name": "Cameroon", "flag": "cm" },
  { "id": "11", "name": "Cape Verde", "flag": "cv" },
  { "id": "12", "name": "Djibouti", "flag": "dj" },
  { "id": "13", "name": "Algeria", "flag": "dz" },
  { "id": "14", "name": "Egypt", "flag": "eg" },
  { "id": "15", "name": "Western Sahara", "flag": "eh" },
  { "id": "16", "name": "Eritrea", "flag": "er" },
  { "id": "17", "name": "Ethiopia", "flag": "et" },
  { "id": "18", "name": "Gabon", "flag": "ga" },
  { "id": "19", "name": "Ghana", "flag": "gh" },
  { "id": "20", "name": "Gambia", "flag": "gm" },
  { "id": "21", "name": "Guinea", "flag": "gn" },
  { "id": "22", "name": "Equatorial Guinea", "flag": "gq" },
  { "id": "23", "name": "Guinea-Bissau", "flag": "gw" },
  { "id": "24", "name": "Kenya", "flag": "ke" },
  { "id": "25", "name": "Comoros", "flag": "km" },
  { "id": "26", "name": "Liberia", "flag": "lr" },
  { "id": "27", "name": "Lesotho", "flag": "ls" },
  { "id": "28", "name": "Libya", "flag": "ly" },
  { "id": "29", "name": "Morocco", "flag": "ma" },
  { "id": "30", "name": "Madagascar", "flag": "mg" },
  { "id": "31", "name": "Mali", "flag": "ml" },
  { "id": "32", "name": "Mauritania", "flag": "mr" },
  { "id": "33", "name": "Mauritius", "flag": "mu" },
  { "id": "34", "name": "Malawi", "flag": "mw" },
  { "id": "35", "name": "Mozambique", "flag": "mz" },
  { "id": "36", "name": "Namibia", "flag": "na" },
  { "id": "37", "name": "Niger", "flag": "ne" },
  { "id": "38", "name": "Nigeria", "flag": "ng" },
  { "id": "39", "name": "Réunion", "flag": "re" },
  { "id": "40", "name": "Rwanda", "flag": "rw" },
  { "id": "41", "name": "Seychelles", "flag": "sc" },
  { "id": "42", "name": "Sudan", "flag": "sd" },
  { "id": "43", "name": "Sierra Leone", "flag": "sl" },
  { "id": "44", "name": "Senegal", "flag": "sn" },
  { "id": "45", "name": "Somalia", "flag": "so" },
  { "id": "46", "name": "South Sudan", "flag": "ss" },
  { "id": "47", "name": "Sao Tome and Principe", "flag": "st" },
  { "id": "48", "name": "Swaziland", "flag": "sz" },
  { "id": "49", "name": "Chad", "flag": "td" },
  { "id": "50", "name": "Togo", "flag": "tg" },
  { "id": "51", "name": "Tunisia", "flag": "tn" },
  { "id": "52", "name": "Tanzania, United Republic of", "flag": "tz" },
  { "id": "53", "name": "Uganda", "flag": "ug" },
  { "id": "54", "name": "Mayotte", "flag": "yt" },
  { "id": "55", "name": "South Africa", "flag": "za" },
  { "id": "56", "name": "Zambia", "flag": "zm" },
  { "id": "57", "name": "Zimbabwe", "flag": "zw" }
]


const CountrySelectionScreen = () => {
  const navigation = useNavigation();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);  // Loader state


  const toggleCountrySelection = (country) => {
    setSelectedCountries((prev) => {
      if (prev.includes(country)) {
        return prev.filter((item) => item !== country);
      } else {
        return [...prev, country];
      }
    });
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(search.toLowerCase())
  );



  const handleContinue = async () => {
    console.log("THE SELECTED selectedCountries");
    console.log(selectedCountries);
        try {
            setLoading(true);
            let response = await authService.updateUserCountriesOfInterest(selectedCountries);

            if (response.success) {
                setLoading(false);
                console.log("User updated successfully:", response.user);
                navigation.navigate('InterestsSelectionScreen');
            } else {
                setLoading(false);
                console.error("Error updating user:", response.error);
            }

        } catch (error) {
            setLoading(false);
            console.error("Error updating user:", error);
        }
  };

  return (
    <KeyboardAvoidingView 
      style={styles.container} 
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
       <View style={styles.wrapper}>

       <Text style={styles.title}>What countries do you want to go to?</Text>
      <Text style={styles.subtitle}>We’ll use this information to personalize recommendations for you</Text>
      <View style={styles.searchContainer}>
        <Image source={require('../assets/search.png')} style={styles.searchIcon} />
      <TextInput
        style={styles.searchInput}
        placeholder="Search countries"
        value={search}
        onChangeText={setSearch}
      />
      </View>
      <View style={styles.selectedCountriesContainer}>
        {selectedCountries.map((country) => (
          <View key={country.id} style={styles.selectedCountry}>
            
            <Image source={{uri: 'https://bucket.traaple.com/flags/' + country.flag + '.png'}} style={styles.selectedFlag} />
            <Text>{country.name}</Text>
            <TouchableOpacity onPress={() => toggleCountrySelection(country)}>
              <Image source={require('../assets/cancel.png')} style={styles.cancelIcon} />
            </TouchableOpacity>
          </View>
        ))}
      </View>
      <FlatList
       data={filteredCountries.filter((country) => !selectedCountries.includes(country))}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <View style={styles.countryItem}>
            <View style={styles.countryItemTitle}>
            <View style={styles.flagContainer}>
            <Image source={{uri: 'https://bucket.traaple.com/flags/' + item.flag + '.png'}} style={styles.flag} />
            </View>
            <Text>{item.name}</Text>
            </View>
            <TouchableOpacity onPress={() => toggleCountrySelection(item)}>
              <Image source={require('../assets/plus.png')} style={styles.plusIcon} />
            </TouchableOpacity>
          </View>
        )}
      />
      <View style={styles.navigationContainer}>
      <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
          <Image source={require('../assets/back.png')} style={styles.backIcon} />
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.continueButton, selectedCountries.length === 0 && styles.continueButtonDisabled]}
          onPress={() => { 
            handleContinue();
           }}
          disabled={selectedCountries.length === 0}
        >
          {loading ? <ActivityIndicator size="small" color="#FFFFFF" /> : <Text style={styles.continueText}>Continue</Text>}
        </TouchableOpacity>
      </View>
       </View>

    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#FFFFFF',
  },
  wrapper: {
    width: '50%',
    maxWidth: 400,
    marginHorizontal: 'auto'
  },
  title: {
    fontSize: 27,
    fontWeight: '600',
    color: '#1A1A1A',
    marginBottom: 10,
    marginTop: 60
  },
  subtitle: {
    fontSize: 17,
    color: '#6E6E6E',
    marginBottom: 25,
  },
  searchContainer: {
    borderColor: '#E0E0E0',
    borderWidth: 1,
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingHorizontal: 15,
    height: 50
  },
  searchIcon: {
    width: 20,
    height: 20,
  },
  searchInput: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    fontSize: 16,
    height: '100%',
    width: '100%',
  },
  selectedCountriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  selectedCountry: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#F0F0F0',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 10,
    margin: 5,
    gap: 10
  },
  cancelIcon: {
    width: 20,
    height: 20,
  },
  plusIcon: {
    width: 20,
    height: 20,
    marginLeft: 'auto',
  },
  flagContainer: {
    marginRight: 5,
    backgroundColor: '#F9F9F9',
    borderRadius: 10,
    padding: 10,
  },
  flag: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },
  selectedFlag: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
  removeText: {
    marginLeft: 5,
    color: '#FF0000',
  },
  countryItemTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  countryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    justifyContent: 'space-between',
  },
  addText: {
    marginLeft: 'auto',
    color: '#0066CC',
  },
  navigationContainer: {
    flexDirection: 'row',
    bottom: 20,
    top: 20,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingBottom: 20,
    paddingTop: 10,
  },
  backButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 50,
    borderColor: '#005FC5',
    backgroundColor: '#E6EFF9',
    borderWidth: 1,
    marginRight: 10,
    maxWidth: 70,
  },
  backIcon: {
    width: 20,
    height: 20,
  },
  backText: {
    fontSize: 16,
    color: '#1A1A1A',
  },
  continueButton: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    borderRadius: 25,
    backgroundColor: '#0066CC',
  },
  continueButtonDisabled: {
    backgroundColor: '#E0E0E0',
  },
  continueText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default CountrySelectionScreen;
