import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, ScrollView, Alert, Linking, Dimensions } from 'react-native';
import experienceService from '../services/experienceService';
import { useNavigation } from '@react-navigation/native';
import AppHeader from '../components/organisms/AppHeader';
import { formatDate } from '../utils/dateFormat';
// import { useStripe, initPaymentSheet, presentPaymentSheet } from '@stripe/stripe-react-native';
import ProductPrice from '../components/atoms/ProductPrice';


const ConfirmAndPayScreen = ({ route }) => {
  const navigation = useNavigation();
  const params = route.params;
  // const { confirmPayment } = useStripe();
  const { width } = Dimensions.get('window');
  const charLimit = Math.floor(width / 14.5);

  const [paymentMethod, setPaymentMethod] = useState('Card');
  const [experience, setExperience] = useState(null);
  const [serviceFee, setServiceFee] = useState(15);
  const [loading, setLoading] = useState(false);
  const [cancellationDate, setCancellationDate] = useState('');

  useEffect(() => {
    const fetchExperienceDetails = async () => {
      try {
        const data = await experienceService.getExperienceDetails(params.experienceId);
        setExperience(data);
        console.log("Package details fetched successfully:");
      } catch (error) {
        console.error("Error fetching experience details:", error.response ? error.response.data : error);
      }
    };

    fetchExperienceDetails();

    // Calculate the cancellation date
    const today = new Date();
    const cancellationDate = new Date(today.setDate(today.getDate() + 10));
    setCancellationDate(formatDate(cancellationDate));
  }, [params.experienceId]);

  const handlePaymentChange = (method) => {
    setPaymentMethod(method);
  };

  const handleLearnMorePress = () => {
    Linking.openURL('https://traaple.com/cancellation-policy');
  };

  const handleConfirmAndPay = async () => {navigation.navigate('PaymentSuccessfulScreen');

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add +1 because months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const bookingData = {
      experienceId: params.experienceId,
      numberOfGuests: params.guests,
      date: "2023-08-15",
      time: params.timeOfDay,
    };

    try {

      console.log("Booking data:", bookingData);
      const bookingResponse = await experienceService.bookExperience(bookingData);
      console.log("Booking successful:", bookingResponse);

      // Navigate to payment screen with necessary data
      navigation.navigate('PayScreen', {
        bookingId: bookingResponse.id,
        totalPrice: bookingResponse.total_price,
        paymentMethod,
      });
    } catch (error) {
      console.error("Error booking experience:", error.response ? error.response.data : error);
    }
  };





  const handlePayPress = async () => {
    setLoading(true);
    navigation.navigate('PaymentSuccessfulScreen');
    try {
      // const paymentIntentResponse = await experienceService.createPaymentIntent(experience?.price);
  
      // const { error: initSheetError } = await initPaymentSheet({
      //   paymentIntentClientSecret: paymentIntentResponse.clientSecret,
      //   merchantDisplayName: 'Your Merchant Name',
      // });
  
      // if (initSheetError) {
      //   Alert.alert('Error initializing payment sheet', initSheetError.message);
      //   return;
      // }
  
      // const { error: paymentSheetError } = await presentPaymentSheet();
  
      // if (paymentSheetError) {
      //   Alert.alert(`Payment failed`, paymentSheetError.message);
      // } else {
      //   // Alert.alert('Payment successful', 'Your payment was successful!');

      //   // go to success screen
      //   navigation.navigate('PaymentSuccessfulScreen');
      // }
    } catch (error) {
      Alert.alert('Payment error', error.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <AppHeader title="Confirm and pay" onGoBack={() => { navigation.goBack() }} />
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.section}>
          <View style={styles.experienceInfo}>
            <Image source={{ uri: experience?.image }} style={styles.experienceImage} />
            <View style={styles.experienceDetails}>
              <Text style={styles.experienceTitle}>{experience?.title.length > charLimit ? `${experience?.title.substring(0, charLimit-3)}...` : experience?.title}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 5 }}>
                <Image source={require('../assets/tflag.png')} style={{ width: 15, height: 15, marginRight: 5 }} />
                <Text style={{ color: '#6C7681', fontSize: 16 }}>
                  {experience?.location}
                </Text>
              </View>
              <View style={{ backgroundColor: '#000C16', borderRadius: 5, padding: 8, alignItems: 'center', width: '72%' }}>
                <Text style={{ color: '#fff' }}>GHS {experience?.price}/person</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Date</Text>
          <Text style={styles.sectionContent}>{formatDate(params.date)}, {params.timeOfDay}</Text>
          <Text style={styles.sectionTitle}>Guests</Text>
          <Text style={styles.sectionContent}>{params.guests} persons</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Price</Text>
          <View style={styles.priceRow}>
            <Text style={styles.priceLabel}><ProductPrice amount={experience?.price} fontSize={14} fontWeight="normal" color="#6E6E6E" /> x {params.guests}</Text>
            <Text style={styles.priceValue}><ProductPrice amount={experience?.price * params.guests} fontSize={14} fontWeight="normal" color="#000000" /></Text>
          </View>
          <View style={styles.priceRow}>
            <Text style={styles.priceLabel}>Service fee</Text>
            <Text style={styles.priceValue}><ProductPrice amount={serviceFee/100*experience?.price} fontSize={14} fontWeight="normal" color="#000000" /></Text>
          </View>
          <View style={styles.priceRow}>
            <Text style={styles.priceLabel}>Total</Text>
            <Text style={styles.totalPriceValue}><ProductPrice amount={(experience?.price * params.guests) + serviceFee/100*experience?.price} fontSize={14} fontWeight="normal" color="#000000" /></Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Payment</Text>
          <TouchableOpacity onPress={() => handlePaymentChange('MobileMoney')} style={styles.paymentMethod}>
            <Text style={styles.paymentMethodText}>
              Mobile Money
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePaymentChange('Card')} style={styles.paymentMethod}>
            <Text style={styles.paymentMethodText}>
              Card
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handlePaymentChange('Stripe')} style={styles.paymentMethod}>
            <Text style={styles.paymentMethodText}>
              Card (Stripe)
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Cancellation policy</Text>
          <Text style={styles.sectionContent}>
            Get a full refund if you cancel by {cancellationDate}.{' '}
            <Text style={styles.learnMore} onPress={handleLearnMorePress}>Learn more</Text>
          </Text>
        </View>
        <TouchableOpacity
          style={styles.confirmButton}
          onPress={paymentMethod == "Stripe" ? handlePayPress : handleConfirmAndPay}
        >
          <Text style={styles.confirmButtonText}>Confirm & pay</Text>
        </TouchableOpacity>
      </ScrollView>
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: '#F9F9F9',
    padding: 20,
    paddingTop: 40,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  backButton: {
    marginRight: 10,
  },
  backButtonText: {
    fontSize: 24,
    color: '#000',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
  },
  section: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  experienceInfo: {
    flexDirection: 'row',
  },
  experienceImage: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 15,
  },
  experienceDetails: {
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
  },
  experienceTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  experienceLocation: {
    fontSize: 14,
    color: '#6E6E6E',
  },
  experiencePrice: {
    fontSize: 14,
    color: '#000',
    marginTop: 5,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#6E6E6E',
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 14,
    color: '#000',
    marginBottom: 10,
  },
  priceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  priceLabel: {
    fontSize: 14,
    color: '#6E6E6E',
  },
  priceValue: {
    fontSize: 14,
    color: '#000',
  },
  serviceFee: {
    fontSize: 14,
    color: '#0066CC',
    marginBottom: 5,
  },
  totalPriceValue: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000',
  },
  paymentMethod: {
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  paymentMethodText: {
    fontSize: 14,
    color: '#000',
  },
  learnMore: {
    color: '#0066CC',
    textDecorationLine: 'underline',
  },
  confirmButton: {
    backgroundColor: '#0066CC',
    borderRadius: 25,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  confirmButtonText: {
    fontSize: 18,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});

export default ConfirmAndPayScreen;
