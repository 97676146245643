// utils/displayPrice.js
import authService from '../services/authService';

export const displayPrice = async (amount) => {

    if (typeof amount === "string") {
        amount = parseFloat(amount); // Convert string to number
    }

    console.log("THE AMOUNT CHECK");
    console.log(typeof amount); // Should be 'number'
    console.log(amount); // Check its value


    try {
        // Fetch the user's profile to get the currency and exchange rate
        const response = await authService.getUserProfile();
        const currency = response.user.currency || 'USD';
        const exchangeRate = response.user.exchangeRate || 1;

        // Convert the amount using the exchange rate
        const convertedAmount = amount * exchangeRate;

        // Format the converted amount to two decimal places
        const formattedAmount = convertedAmount.toFixed(2);

        // Display the formatted price with the currency symbol
        return `${formattedAmount} ${currency}`;
    } catch (error) {
        console.error('Error displaying price:', error);
        return `${amount.toFixed(2)} USD`; // Fallback if there's an error
    }
};
