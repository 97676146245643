import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground, Dimensions, StatusBar, Animated } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const images = [
  { id: '1', src: require('../assets/welcome/1.png'), title: 'Discover experiences across Africa' },
  { id: '2', src: require('../assets/welcome/2.png'), title: 'Enjoy seamless booking with trusted partners' },
  { id: '3', src: require('../assets/welcome/1.png'), title: 'Personalized recommendations for you' },
];

const WelcomeScreen = () => {
  const navigation = useNavigation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(1)).current; // Initial opacity value is 1

  useEffect(() => {
    const interval = setInterval(() => {
      // Start fade-out animation
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => {
        // Change index after fade-out
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        // Start fade-in animation
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
        }).start();
      });
    }, 5000); // Change image every 5000 ms (5 seconds)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [fadeAnim]);

  return (
    <ImageBackground source={images[currentIndex].src} style={styles.background}>
      <StatusBar style="dark-content" />
      <Animated.View style={[styles.container, { opacity: fadeAnim }]}>
        <Text style={styles.title}>{images[currentIndex].title}</Text>
      </Animated.View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('SignupScreen')}>
          <Text style={styles.buttonText}>Sign up</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.loginButton} onPress={() => navigation.navigate('LoginScreen')}>
          <Text style={styles.loginButtonText}>Log in</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('QuickGlanceScreen')}>
          <Text style={styles.linkText}>Continue as guest</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: Dimensions.get('window').width, // Ensure full width
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 150, // Adjust this value as needed
    paddingHorizontal: 10,
    marginTop: '100%',
  },
  title: {
    fontSize: 40,
    color: 'white',
    fontFamily: 'MontBlack',
    marginBottom: 130,
    width: '90%',
    textAlign: 'left',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    alignItems: 'center',
  },
  button: {
    width: '90%',
    backgroundColor: 'white',
    padding: 13,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  buttonText: {
    fontSize: 18,
    color: '#000',
    fontWeight: 'bold',
  },
  loginButton: {
    width: '90%',
    backgroundColor: '#000',
    borderWidth: 1,
    borderColor: '#fff',
    padding: 15,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  loginButtonText: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },
  linkText: {
    color: 'white',
    fontSize: 16,
    marginVertical: 20,
  },
});

export default WelcomeScreen;
