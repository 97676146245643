import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, useWindowDimensions } from 'react-native';

const WhyChooseTraaple = () => {
    const { width } = useWindowDimensions();

    const features = [
        {
            title: 'Curated Travel Experiences',
            description: 'At Traaple, we make your travels across Africa unforgettable. Our curated experiences are designed just for you—tailored to match your taste, budget and preferences.',
        },
        {
            title: 'Personalised Recommendations',
            description: 'No more endless searching. With Traaple, your travel plan is crafted specifically for you based on your interests and past travel experiences.',
        },
        {
            title: 'Trusted Network of Providers',
            description: 'Access a wide range of trusted and reliable travel providers across Africa. Whether you’re looking for activities, tours, or exclusive events, we’ve got you covered.',
        },
        {
            title: 'Community of Travellers',
            description: 'Join a community of fellow travellers. Share experiences, get tips and connect with like-minded people through our forums and interactive features.',
        },
    ];

    // Determine item width based on screen size
    const getItemWidth = () => {
        if (width >= 1024) return '22%'; // large screens
        if (width >= 768) return '45%'; // tablets
        return '100%'; // phones
    };

    return (
        <View style={styles.container}>
            <View style={styles.chooseTitle}>
                <Text style={styles.heading}>Why Choose Traaple</Text>
                <TouchableOpacity>
                    <Text style={styles.readMore}>Read more about Traaple</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.featuresContainer}>
                {features.map((feature, index) => (
                    <View key={index} style={[styles.featureBox, { width: getItemWidth() }]}>
                        <View style={styles.iconContainer}>
                            <Image source={require('../assets/land_flag.png')} style={styles.icon} />
                        </View>
                        <Text style={styles.featureTitle}>{feature.title}</Text>
                        <Text style={styles.featureDescription}>{feature.description}</Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    heading: {
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
        flex: 1,
    },
    chooseTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        flexWrap: 'wrap',
    },
    featuresContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 16,
    },
    featureBox: {
        marginBottom: 20,
        alignItems: 'flex-start',
    },
    iconContainer: {
        width: 50,
        height: 50,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F0',
        marginBottom: 10,
    },
    icon: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
    },
    featureTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    featureDescription: {
        fontSize: 14,
        color: '#6C7681',
    },
    readMore: {
        fontSize: 14,
        color: '#005FC5',
        marginTop: 10,
    },
});

export default WhyChooseTraaple;
