import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, ScrollView } from 'react-native';
import { searchExperiences } from '../services/experienceService';

const SearchScreen = ({ closeModal, navigation }) => {
  const [searchType, setSearchType] = useState('Everything');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showRegionList, setShowRegionList] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('Everywhere in Africa');

  const searchTypes = ['Everything', 'Activities', 'Attractions', 'Tours', 'Events', 'Restaurants', 'Nightlife & Entertainment'];
  const regions = ['Everywhere in Africa', 'Northern Africa', 'Southern Africa', 'Eastern Africa', 'Western Africa', 'Central Africa'];

  const searchTypeImages = {
    Everything: require('../assets/atbeach.jpeg'),
    Activities: require('../assets/interests/activities.png'),
    Attractions: require('../assets/interests/attractions.png'),
    Tours: require('../assets/interests/tours.png'),
    Events: require('../assets/interests/events.png'),
    Restaurants: require('../assets/interests/restaurants.png'),
    'Nightlife & Entertainment': require('../assets/interests/nightlife.png'),
  };

  const handleSearch = () => {
    const params = new URLSearchParams();

    if (searchType) params.append('searchType', searchType);
    if (selectedRegion) params.append('region', selectedRegion);

    const queryString = params.toString();
    console.log(queryString);
    
    // Assuming `searchExperiences` is a function that performs the search
    searchExperiences(queryString).then(response => {
      if (response) {
        navigation.navigate('ExperienceListScreen', { query: queryString });

        closeModal();
      }
    });
  };

  const remainingRegions = regions.filter(region => region !== selectedRegion);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => {
          closeModal();
          // navigation.goBack()
          }} style={styles.backButton}>
          <Image source={require('../assets/close.png')} style={styles.closeIcon} />
        </TouchableOpacity>
        <Text style={styles.title}>Search</Text>
      </View>

      <ScrollView contentContainerStyle={styles.scrollContent}>
        {/* What do you want to discover section */}
        <View style={styles.section}>
          <TouchableOpacity onPress={() => setIsCollapsed(!isCollapsed)} style={styles.collapsibleHeader}>
            <View style={styles.collapsibleHeaderText}>
              <Text style={styles.sectionTitle}>What do you want to discover?</Text>
              {isCollapsed && <Text style={styles.collapsedText}>{searchType}</Text>}
            </View>
            <Image source={require('../assets/chevron-down.png')} style={styles.arrowIcon} />
          </TouchableOpacity>
          {!isCollapsed && (
            <View style={styles.searchTypeGrid}>
              {searchTypes.map(type => (
                <TouchableOpacity
                  key={type}
                  style={[styles.searchTypeButton, searchType === type && styles.selectedButton]}
                  onPress={() => {
                    setSearchType(type);
                    setIsCollapsed(true);
                  }}
                >
                  <View style={styles.searchTypeImageContainer}>
                    <Image source={searchTypeImages[type]} style={styles.searchTypeImage} />
                  </View>
                  <Text style={[styles.searchTypeText, searchType === type && styles.selectedText]}>{type}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>

        {/* Where do you want to go section */}
        <View style={styles.section}>
          <TouchableOpacity onPress={() => setShowRegionList(!showRegionList)} style={styles.collapsibleHeader}>
            <View style={styles.collapsibleHeaderText}>
              <Text style={styles.sectionTitle}>Where do you want to go?</Text>
              {!showRegionList && <Text style={styles.collapsedText}>{selectedRegion}</Text>}
            </View>
            <Image source={require('../assets/chevron-down.png')} style={styles.arrowIcon} />
          </TouchableOpacity>
          {showRegionList && (
            <View style={styles.regionList}>
              <View style={styles.regionItem}>
                <Text style={styles.regionText}>{selectedRegion}</Text>
                <Image source={require('../assets/radio.png')} style={styles.radioIcon} />
              </View>
              {remainingRegions.map(region => (
                <TouchableOpacity
                  key={region}
                  style={styles.regionItem}
                  onPress={() => {
                    setSelectedRegion(region);
                    setShowRegionList(false);
                  }}
                >
                  <Text style={styles.regionText}>{region}</Text>
                  <Image source={require('../assets/empty-radio.png')} style={styles.radioIcon} />
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      </ScrollView>

      <View style={styles.searchButtonContainer}>
        <TouchableOpacity style={styles.searchButton} onPress={handleSearch}>
          <Text style={styles.searchButtonText}>Search</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f8f8f8',
  },
  scrollContent: {
    padding: 10,
    paddingBottom: 80,
  },
  section: {
    marginBottom: 20,
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 5 },
    shadowOpacity: 0.05,
    shadowRadius: 10,
    elevation: 2,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: '#6C7681',
  },
  searchTypeGrid: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  searchTypeButton: {
    width: '48%',
    padding: 15,
    borderRadius: 10,
    backgroundColor: '#F9F9F9',
    marginBottom: 10,
    alignItems: 'flex-start',
    borderWidth: 1,
    borderColor: 'transparent',
  },
  selectedButton: {
    borderColor: '#005FC5',
  },
  searchTypeText: {
    color: '#6C7681',
    fontSize: 14,
    marginTop: 5,
  },
  selectedText: {
    color: '#005FC5',
    fontWeight: 'bold',
  },
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    borderRadius: 10,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#d3d3d3',
  },
  dropdownText: {
    color: '#6C7681',
    fontSize: 14,
  },
  destinationInput: {
    padding: 15,
    borderRadius: 10,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#d3d3d3',
  },
  regionList: {
    marginTop: 10,
  },
  regionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderRadius: 10,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  regionText: {
    color: '#6C7681',
    fontSize: 14,
  },
  radioIcon: {
    width: 20,
    height: 20,
  },
  searchButtonContainer: {
    position: 'absolute',
    bottom: 20,
    paddingHorizontal: 10,
    paddingBottom: 30,
    paddingTop: 10,
    width: '100%',
    alignItems: 'center',
    bottom: 0,
    backgroundColor: '#fff',
  },
  searchButton: {
    alignItems: 'center',
    backgroundColor: '#005FC5',
    padding: 15,
    borderRadius: 50,
    width: '100%',
  },
  searchButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
  searchTypeImageContainer: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  searchTypeImage: {
    width: '100%',
    height: '100%',
    borderRadius: 50,
  },
  header: {
    paddingTop: 50,
    paddingBottom: 20,
    marginBottom: 20,
  },
  backButton: {
    color: '#1A1A1A',
    left: 15,
    top: 5,
    alignItems: 'center',
    position: 'absolute',
    paddingTop: 45,
  },
  title: {
    fontSize: 20,
    fontWeight: '600',
    color: '#1A1A1A',
    width: '100%',
    textAlign: 'center',
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
  collapsibleHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collapsibleHeaderText: {
    flex: 1,
  },
  collapsedText: {
    color: '#000000',
    fontSize: 15,
    marginTop: 5,
    fontWeight: 'bold',
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
});

export default SearchScreen;
